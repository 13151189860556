export default {
    namespaced: true,

    state: {
        offersList: null,
        isLoading: true,
    },

    getters: {
        offersList (state) {
            return state.offersList;
        },
        isLoading (state) {
            return state.isLoading;
        },
    },

    mutations: {
        SET_OFFERS_LIST (state, value) {
            state.offersList = value;
        },
        SET_IS_LOADING (state, value) {
            state.isLoading = value;
        },
    },

    actions: {
        async getOffers ({ dispatch }) {
            return dispatch('getOffersList');
        },

        getOffersList ({ commit }) {
            commit('SET_IS_LOADING', true);
            return axios.get('/api/getOffers').then(response => {
                if (response.data.success) {
                    commit('SET_OFFERS_LIST', response.data.offers);
                    commit('SET_IS_LOADING', false);
                } else {
                    commit('SET_OFFERS_LIST', []);
                    commit('SET_IS_LOADING', true);
                }
            }).catch(() => {
                commit('SET_OFFERS_LIST', []);
                commit('SET_IS_LOADING', true);
            });
        }
    }
}
