export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: null,
        roles: null,
        allRoles: null,
        isLoading: true,
    },

    getters: {
        authenticated (state) {
            return state.authenticated;
        },
        user (state) {
            return state.user;
        },
        roles (state) {
            return state.roles;
        },
        allRoles (state) {
            return state.allRoles;
        },
        isLoading (state) {
            return state.isLoading;
        },
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value;
        },
        SET_USER (state, value) {
            state.user = value;
        },
        SET_ROLES (state, value) {
            state.roles = value;
        },
        SET_ALL_ROLES (state, value) {
            state.allRoles = value;
        },
        SET_IS_LOADING (state, value) {
            state.isLoading = value;
        },
    },

    actions: {
        async login ({ dispatch }) {
            return dispatch('me');
        },

        async logout ({ dispatch }) {
            return dispatch('me');
        },

        async getAllRoles ({ dispatch }) {
            return dispatch('getAllRolesList');
        },

        me ({ commit }) {
            commit('SET_IS_LOADING', true);
            return axios.get('/api/checkAuth').then(response => {
                if (response.data.success) {
                    axios.get('/api/user').then(response => {
                        commit('SET_AUTHENTICATED', true);
                        commit('SET_USER', response.data);
                        commit('SET_IS_LOADING', false);
                    }).catch(() => {
                        commit('SET_AUTHENTICATED', false);
                        commit('SET_USER', null);
                        commit('SET_IS_LOADING', true);
                    })

                    axios.get('/api/getRoles').then(response => {
                        if (response.data.success) {
                            commit('SET_ROLES', response.data.roles);
                        } else {
                            commit('SET_ROLES', null);
                        }
                    }).catch(() => {
                        commit('SET_ROLES', null);
                    })
                } else {
                    commit('SET_AUTHENTICATED', false);
                    commit('SET_USER', null);
                    commit('SET_IS_LOADING', false);
                    commit('SET_ROLES', null);
                }
            });
        },

        getAllRolesList ({ commit }) {
            commit('SET_IS_LOADING', true);
            return axios.get('/api/getAllRoles').then(response => {
                commit('SET_ALL_ROLES', response.data);
                commit('SET_IS_LOADING', false);
            }).catch(() => {
                commit('SET_ALL_ROLES', []);
                commit('SET_IS_LOADING', true);
            });
        },
    }
}
