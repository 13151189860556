<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-5>
                <Alert v-if="errors.visible" :error="errors.message" />
                <h1>Ziņas saturs</h1>
            </v-flex>

            <v-col cols="12" offset-md="3" md="6" class="my-5">
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Ziņas veids
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ data.option }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Klients
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ data.name }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            E-pasts
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ data.email }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Telefona Numurs ({{ data.phoneCountry }})
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ data.phoneNumber }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Apraksts
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ data.description }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Iesūtīts
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            <time-ago locale="en" :datetime="data.createdAt"></time-ago>
                        </p>
                    </v-col>
                </v-row>
            </v-col>

            <v-flex offset-md3 md6 xs12>
                <v-divider></v-divider>
            </v-flex>

            <v-flex
                v-if="data.replies.length"
                xs12
                sm6
                offset-sm3
                class="text-center"
                mt-8
            >
                <h1>Atbildes</h1>
            </v-flex>

            <v-flex xs12
                v-for="(reply, index) in data.replies"
                :key="index"
            >
                <v-col cols="12" offset-md="3" md="6" class="my-5" >
                    <v-row no-gutters>
                        <v-col cols="4">
                            <p class="font-weight-regular">
                                Apraksts #{{ index + 1 }}
                            </p>
                        </v-col>
                        <v-col cols="8">
                            <p class="font-weight-bold">
                                {{ reply.description }}
                            </p>
                        </v-col>

                        <v-col cols="4">
                            <p class="font-weight-regular">
                                Atbildēts
                            </p>
                        </v-col>
                        <v-col cols="8">
                            <p class="font-weight-bold">
                                <time-ago locale="en" :datetime="reply.createdAt"></time-ago>
                            </p>
                        </v-col>
                    </v-row>
                </v-col>

                <v-flex offset-md3 md6 xs12>
                    <v-divider></v-divider>
                </v-flex>
            </v-flex>

            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-8>
                <h1>Pievienot atbildi</h1>
            </v-flex>

            <v-flex xs12 sm6 offset-sm3 mt-5>
                <form @submit.prevent="reply">
                    <v-layout column>
                        <v-flex>
                            <v-textarea
                                solo
                                name="newReplyText"
                                v-model="form.newReplyText"
                                id="newReplyText"
                                label="Apraksts"
                                :error-messages="replyTextErrors"
                                @input="$v.form.newReplyText.$touch()"
                                @blur="$v.form.newReplyText.$touch()"
                            ></v-textarea>
                        </v-flex>
                        <v-flex>
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading || !getIsActionAllowed('replyOnMessages')"
                                small
                            >
                                Atbildēt
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, maxLength, minLength } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';
    import { TimeAgo } from 'vue2-timeago';

    export default {
        mixins: [ validationMixin ],

        validations: {
            form: {
                newReplyText: { required, minLength: minLength(3), maxLength: maxLength(4096) },
            },
        },

        components: {
            Alert,
            TimeAgo,
        },

        data: () => ({
            form: {
                id: null,
                newReplyText: '',
                recaptcha: '',
            },
            data: {
                option: null,
                name: null,
                email: null,
                phoneNumber: null,
                phoneCountry: null,
                description: null,
                createdAt: null,
                replies: [],
            },
            errors: {
                newReplyText: null,
                message: null,
                visible: false,
            },
            loading: false,
        }),

        computed: {
            replyTextErrors() {
                const errors = [];
                if (this.errors.newReplyText) {
                    errors.push(this.errors.newReplyText[0]);
                    this.errors.newReplyText = null;
                }
                if (!this.$v.form.newReplyText.$dirty) return errors;
                !this.$v.form.newReplyText.required && errors.push('Obligāts lauks');
                !this.$v.form.newReplyText.minLength && errors.push('Jābūt vismaz 3 rakstzīmēm');
                !this.$v.form.newReplyText.maxLength && errors.push('Nedrīkst pārsniegt 4096 rakstzīmes');
                return errors;
            },
            isSuperAdmin() {
                return this.roles.some(record => record.role === 'superAdmin');
            },
            ...mapGetters({
                roles: 'auth/roles',
                messageOptions: 'enums/messageOptions',
            }),
        },

        methods: {
            ...mapActions({
                setMessageAction: 'message/setMessage',
                getMessagesListAction: 'messages/getMessages',
            }),
            reply() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.errors.message = 'Dati satur kļūdas.';
                    this.errors.visible = true;
                    this.setMessageAction( { message: 'Kļūda!', isVisible: true, type: 'error' } );
                    this.resetMessageTimeout(10000);
                } else {
                    this.loading = true;
                    this.$recaptcha('replyOnMessage').then(token => {
                        this.form.recaptcha = token;
                        axios.post('/api/replyOnMessage', this.form).then((response) => {
                            this.loading  = false;
                            this.form.recaptcha = '';
                            if (response.data.success) {
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                                this.getMessagesListAction();
                                this.$router.push({ name: 'Messages' });
                            } else {
                                this.errors.message = response.data.message;
                                this.errors.visible = true;
                                this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );

                                if (response.data.errors) {
                                    if (response.data.errors.newReplyText) {
                                        this.errors.newReplyText = response.data.errors.newReplyText;
                                    }
                                }
                            }
                            this.resetMessageTimeout(10000);
                        }).catch((error) => {
                            this.loading = true;
                            this.form.recaptcha = '';
                            this.setMessageAction( {
                                message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                isVisible: true,
                                type: 'error'
                            } );
                        });
                    });
                }
            },
            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
            getIsActionAllowed(role) {
                if (this.roles) {
                    return this.isSuperAdmin || this.userHasRole(role);
                } else {
                    return false;
                }
            },
            userHasRole(role) {
                return this.roles.some(record => record.role === role);
            },
        },

        created: function() {
            if (!this.$route.params.id
                || !Number.isInteger(parseInt(this.$route.params.id))
                || parseInt(this.$route.params.id) <= 0
            ) {
                this.setMessageAction( { message: 'Ziņa nav atrasta', isVisible: true, type: 'error' } );
                this.$router.push({ name: 'Messages' });
            } else {
                this.loading = true;
                axios.get('/api/getMessage/' + this.$route.params.id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.form.id = response.data.messageData.id;
                        this.data.option = this.messageOptions[response.data.messageData.option];
                        this.data.name = response.data.messageData.name;
                        this.data.email = response.data.messageData.email;
                        this.data.phoneNumber = response.data.messageData.phoneNumber;
                        this.data.phoneCountry = response.data.messageData.phoneCountry;
                        this.data.description = response.data.messageData.description;
                        this.data.createdAt = response.data.messageData.createdAt;

                        response.data.messageData.replies.forEach(reply => {
                            this.data.replies.push( { description: reply.description, createdAt: reply.createdAt } );
                        });
                    } else {
                        this.setMessageAction( { message: 'Ziņa nav atrasta', isVisible: true, type: 'error' } );
                        this.$router.push({ name: 'Messages' });
                    }
                }).catch(error => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                    this.$router.push({ name: 'Messages' });
                });
            }
        },
    }
</script>

<style scoped>

</style>
