export default {
    namespaced: true,

    state: {
        servicesList: null,
        isLoading: true,
    },

    getters: {
        servicesList (state) {
            return state.servicesList;
        },
        isLoading (state) {
            return state.isLoading;
        },
    },

    mutations: {
        SET_SERVICES_LIST (state, value) {
            state.servicesList = value;
        },
        SET_IS_LOADING (state, value) {
            state.isLoading = value;
        },
    },

    actions: {
        async getServices ({ dispatch }) {
            return dispatch('getServicesList');
        },

        getServicesList ({ commit }) {
            commit('SET_IS_LOADING', true);
            return axios.get('/api/getServices').then(response => {
                if (response.data.success) {
                    commit('SET_SERVICES_LIST', response.data.services);
                    commit('SET_IS_LOADING', false);
                } else {
                    commit('SET_SERVICES_LIST', []);
                    commit('SET_IS_LOADING', true);
                }
            }).catch(() => {
                commit('SET_SERVICES_LIST', []);
                commit('SET_IS_LOADING', true);
            });
        }
    }
}
