<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap class="mt-12">
            <v-col cols="12" offset-md="4" md="4">
                <v-flex class="text-center">
                    <h1>Ziņas</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                <v-btn v-if="authenticated && getIsActionVisible(refreshMessagesBtn)"
                    class="hidden-md-and-up float-right mx-2"
                    fab
                    dark
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshMessagesData"
                    :loading="isMessagesLoading" :disabled="isMessagesLoading"
                >
                    <v-icon >
                        {{ refreshMessagesBtn.icon }}
                    </v-icon>
                </v-btn>
                <v-btn v-if="authenticated && getIsActionVisible(refreshMessagesBtn)"
                    class="hidden-sm-and-down float-right mx-2"
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshMessagesData"
                    :loading="isMessagesLoading" :disabled="isMessagesLoading"
                >
                    {{ refreshMessagesBtn.title }}
                    <v-icon right>
                        {{ refreshMessagesBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col v-if="isMessagesLoading" cols="12" md="12">
                <v-flex class="text-center">
                    <v-progress-circular
                        :size="40"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-flex>
            </v-col>

            <v-col v-else-if="!messageList.length" cols="12" md="12">
                <v-flex class="text-center">
                    <h3>Nav ziņu</h3>
                </v-flex>
            </v-col>

            <v-col v-else>
                <v-flex v-if="applyForRepairList.length" xs12 offset-md2 md8>
                    <h3 class="mb-2">Pieteikt remontu</h3>
                    <List :messages="applyForRepairList"/>
                </v-flex>

                <v-flex v-if="applyForItemList.length" xs12 offset-md2 md8>
                    <h3 class="mb-2">Pieteikt preci</h3>
                    <List :messages="applyForItemList"/>
                </v-flex>

                <v-flex v-if="questionList.length" xs12 offset-md2 md8>
                    <h3 class="mb-2">Jautājums</h3>
                    <List :messages="questionList"/>
                </v-flex>

                <v-flex v-if="otherMessageList.length" xs12 offset-md2 md8>
                    <h3 class="mb-2">Cits</h3>
                    <List :messages="otherMessageList"/>
                </v-flex>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import List from '../components/List';
    import Table from '../components/Table';

    export default {
        name: 'App',

        components: {
            List,
            Table
        },

        data: () => ({
            refreshMessagesBtn: { title: 'Atsvaidzināt', path: '', icon: 'mdi-cached', role: 'replyOnMessages' },
        }),

        computed: {
            ...mapGetters({
                authenticated: 'auth/authenticated',
                roles: 'auth/roles',
                messageList: 'messages/messageList',
                unreadMessageCount: 'messages/unreadMessageCount',
                isMessagesLoading: 'messages/isLoading',
            }),
            isSuperAdmin() {
                return this.roles.some(record => record.role === 'superAdmin');
            },
            applyForRepairList() {
                return this.messageList.filter(message =>
                    message.option === 'APPLY_FOR_REPAIR'
                );
            },
            applyForItemList() {
                return this.messageList.filter(message =>
                    message.option === 'APPLY_FOR_ITEM'
                );
            },
            questionList() {
                return this.messageList.filter(message =>
                    message.option === 'QUESTION'
                );
            },
            otherMessageList() {
                return this.messageList.filter(message =>
                    message.option === 'OTHER'
                );
            },
        },

        methods: {
            ...mapActions({
                setMessageAction: 'message/setMessage',
                getMessagesListAction: 'messages/getMessages',
            }),
            refreshMessagesData() {
                this.getMessagesListAction().then(refreshed => {
                    this.setMessageAction( { message: 'Dati atjaunoti!', isVisible: true, type: 'success' } );
                });
            },
            getIsActionVisible(action) {
                if (this.roles) {
                    return this.isSuperAdmin || !action.role || this.userHasRole(action.role);
                } else {
                    return false;
                }
            },
            userHasRole(role) {
                return this.roles.some(record => record.role === role);
            },
        },

        created: function() {
            this.getMessagesListAction();
        },
    }
</script>

<style scoped>

</style>
