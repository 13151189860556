<template>
    <v-alert
        dense
        type="error"
        dark
        dismissible
    >
        {{ error }}
    </v-alert>
</template>

<script>
    export default {
        props: ['error'],
    }
</script>

<style scoped>

</style>
