import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './pages/Home';
import Adverts from './pages/Adverts';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Register from './pages/Register';
import EditUser from './pages/EditUser';
import AddItem from './pages/AddItem';
import EditItem from './pages/EditItem';
import AddService from './pages/AddService';
import EditService from './pages/EditService';
import AddOffer from './pages/AddOffer';
import EditOffer from './pages/EditOffer';
import Account from './pages/Account';
import NewPass from './pages/NewPass';
import AdminControlPanel from './pages/AdminControlPanel';
import Messages from './pages/Messages';
import ViewMessage from './pages/ViewMessage';

import axios from 'axios';

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            path: '*',
            component: NotFound,
            name: 'NotFound',
            allowFullScreen: true
        },
        {
            path: '/',
            component: Home,
            name: 'Home'
        },
        {
            path: '/adverts',
            component: Adverts,
            name: 'Adverts',
        },
        {
            path: '/login',
            component: Login,
            name: 'Login',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (!response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/register',
            component: Register,
            name: 'Register',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth/addNewUser').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/editUser/:id',
            component: EditUser,
            name: 'EditUser',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth/editUser').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/newItem',
            component: AddItem,
            name: 'AddItem',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/editItem/:id',
            component: EditItem,
            name: 'EditItem',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/newService',
            component: AddService,
            name: 'AddService',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/editService/:id',
            component: EditService,
            name: 'EditService',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/newOffer',
            component: AddOffer,
            name: 'AddOffer',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/editOffer/:id',
            component: EditOffer,
            name: 'EditOffer',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/account',
            component: Account,
            name: 'Account',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/newPass',
            component: NewPass,
            name: 'NewPass',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/acp',
            component: AdminControlPanel,
            name: 'AdminControlPanel',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth/enterAdminCp').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/messages',
            component: Messages,
            name: 'Messages',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth/readMessages').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
        {
            path: '/viewMessage/:id',
            component: ViewMessage,
            name: 'ViewMessage',
            beforeEnter: (to, from, next) => {
                axios.get('/api/checkAuth/replyOnMessages').then(response => {
                    if (response.data.success) {
                        return next();
                    }
                    return next({ name: 'NotFound'})
                }).catch(() => {
                    return next({ name: 'NotFound'})
                });
            },
        },
    ],
    mode: 'history',
    linkActiveClass: 'active'
});