import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import items from './items';
import services from './services';
import offers from './offers';
import enums from './enums';
import message from './message';
import users from './users';
import messages from './messages';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        items,
        services,
        offers,
        enums,
        message,
        users,
        messages,
    },
});
