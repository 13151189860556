<template>
    <v-card class="mx-auto" elevation="6">
        <v-list v-if="services" two-line>
            <template
                v-for="(service, index) in services"
            >
                <v-list-item
                    :key="service.title"
                    v-bind:class="{ 'grey darken-3': !service.isActive }"
                >
                    <v-list-item-content>
                        <v-list-item-title v-html="service.title" class="wrap-text"></v-list-item-title>
                        <v-list-item-subtitle v-if="service.note" v-html="service.note" class="wrap-text"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="service.priceInMinorUnit" class="ml-2">
                        <v-list-item-action-text class="blue--text text--lighten-1">
                            Sākot no {{ service.priceInMinorUnit | toRoundedCurrency }}
                        </v-list-item-action-text>
                        <v-list-item-action-text v-if="!service.isActive">Neaktīvs</v-list-item-action-text>
                    </v-list-item-action>

                    <v-list-item-action v-if="authenticated" class="ml-2">
                        <v-btn
                            color="warning"
                            class="ma-1"
                            fab
                            small
                            dark
                            :to="'/editService/' + service.id"
                            :loading="loading" :disabled="loading"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn
                            v-bind:color="service.isActive ? 'error' : 'primary'"
                            class="ma-1"
                            fab
                            small
                            dark
                            v-on:click.prevent="markService(service.id)"
                            :loading="loading" :disabled="loading"
                        >
                            <v-icon>{{ service.isActive ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="index"></v-divider>
            </template>
        </v-list>

        <v-list v-if="users">
            <template
                v-for="(user, index) in users"
            >
                <v-list-item
                    :key="user.username + user.id"
                    v-bind:class="{ 'green darken-4': user.confirmed, 'red darken-4': !user.confirmed }"
                >
                    <v-list-item-content>
                        <v-list-item-title v-html="user.username" class="wrap-text"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-list-item-action-text>{{ user.confirmed ? 'Aktīvs' : 'Neaktīvs' }}</v-list-item-action-text>
                    </v-list-item-action>
                    <v-list-item-action v-if="authenticated">
                        <v-btn v-if="getIsActionVisible(editUserBtn)"
                            color="warning"
                            class="ma-1"
                            fab
                            small
                            dark
                            :to="'/editUser/' + user.id"
                            :loading="loading" :disabled="loading"
                        >
                            <v-icon>{{ editUserBtn.icon }}</v-icon>
                        </v-btn>

                        <v-btn v-if="getIsActionVisible(markUserBtn)"
                            v-bind:color="user.confirmed ? 'error' : 'primary'"
                            class="ma-1"
                            fab
                            small
                            dark
                            v-on:click.prevent="markUser(user.id)"
                            :loading="loading" :disabled="loading"
                        >
                            <v-icon>{{ user.confirmed ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="index"></v-divider>
            </template>
        </v-list>

        <v-list v-if="messages">
            <template
                v-for="(message, index) in messages"
            >
                <v-list-item
                    :key="message.id"
                    v-bind:class="{ 'font-weight-bold blue--text': !message.isRead }"
                    :to="'/viewMessage/' + message.id"
                    :disabled="!getIsActionAllowed('replyOnMessages')"
                >
                    <v-list-item-content>
                        <v-list-item-title v-html="message.name"></v-list-item-title>
                        <v-list-item-subtitle class="text-truncate">
                            {{ message.description.substring(0, 20) + ".." }}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-list-item-action-text>
                            <time-ago locale="en" :datetime="message.createdAt"></time-ago>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="message.id + '_' + index"></v-divider>
            </template>
        </v-list>
    </v-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { TimeAgo } from 'vue2-timeago';

    export default {
        props: [ 'services', 'users', 'messages' ],

        components: {
            TimeAgo,
        },

        data: () => ({
            loading: false,
            errors: {
                message: null,
                visible: false,
            },
            editUserBtn: { title: '', path: '', icon: 'mdi-pencil', role: 'editUser' },
            markUserBtn: { title: '', path: '', icon: '', role: 'markUser' },
        }),

        computed: {
            isSuperAdmin() {
                return this.roles.some(record => record.role === 'superAdmin');
            },
            ...mapGetters({
                authenticated: 'auth/authenticated',
                roles: 'auth/roles',
            }),
        },

        methods: {
            ...mapActions({
                getServiceListAction: 'services/getServices',
                getUsersListAction: 'users/getUsers',
                setMessageAction: 'message/setMessage',
            }),
            markService(id) {
                this.loading = true;
                axios.get('/api/markService/' + id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                        this.getServiceListAction();
                    } else {
                        this.errors.message = response.data.message;
                        this.errors.visible = true;
                        this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                    }
                    this.resetMessageTimeout(10000);
                }).catch((error) => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
            },
            markUser(id) {
                this.loading = true;
                axios.get('/api/markUser/' + id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                        this.getUsersListAction();
                    } else {
                        this.errors.message = response.data.message;
                        this.errors.visible = true;
                        this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                    }
                    this.resetMessageTimeout(10000);
                }).catch((error) => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
            },
            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
            getIsActionVisible(action) {
                if (this.roles) {
                    return this.isSuperAdmin || !action.role || this.userHasRole(action.role);
                } else {
                    return false;
                }
            },
            getIsActionAllowed(role) {
                if (this.roles) {
                    return this.isSuperAdmin || this.userHasRole(role);
                } else {
                    return false;
                }
            },
            userHasRole(role) {
                return this.roles.some(record => record.role === role);
            },
        },
    }
</script>

<style scoped>
    .wrap-text {
        white-space: normal;
    }
</style>
