<template>
    <v-card
        :loading="loading"
        class="ma-2"
        :outlined="!item.isActive"
        :raised="!item.isActive"
        elevation="6"
    >
        <Alert v-if="errors.visible" :error="errors.message" />

        <div v-if="isForListing">
            <v-card-text v-if="authenticated" class="text-right">
                <div v-if="!item.isActive" class="grey--text text-left">Neaktīvs</div>

                <v-btn
                    color="warning"
                    class="mx-2"
                    fab
                    small
                    dark
                    :to="'/editItem/' + item.id"
                    :loading="loading" :disabled="loading"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                    v-bind:color="item.isActive ? 'error' : 'primary'"
                    fab
                    small
                    dark
                    v-on:click.prevent="markItem(item.id)"
                    :loading="loading" :disabled="loading"
                >
                    <v-icon>{{ item.isActive ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
            </v-card-text>

            <v-carousel
                v-if="item.images.length > 0"
                cycle
                height="auto"
                hide-delimiter-background
                show-arrows-on-hover
                hide-delimiters
            >
                <v-carousel-item
                    v-for="(image, i) in item.images"
                    :key="i"
                >
                    <v-img
                        :src="image.path + image.name"
                        contain
                        max-height="400"
                    ></v-img>
                </v-carousel-item>
            </v-carousel>

            <v-card-title style="word-break: break-word;">{{ item.title }}</v-card-title>
            <v-card-text>
                <v-chip v-if="item.priceInMinorUnit" color="primary" class="subtitle-2">
                    {{ item.priceInMinorUnit | toCurrency }}
                </v-chip>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-center">
                <v-btn
                    icon
                    @click="show = !show"
                >
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
                <div v-show="show">
                    <v-card-text class="text--primary">
                        <div v-if="item.state" >
                            <span class="text--secondary">Stāvoklis: </span>
                            {{ states[item.state] }}
                        </div>
                        <div v-if="item.brand" >
                            <span class="text--secondary">Zīmols: </span>
                            {{ brands[item.brand] }}
                        </div>
                        <div v-if="item.category" >
                            <span class="text--secondary">Kategorija: </span>
                            {{ categories[item.category] }}
                        </div>
                        <div v-if="item.category === 'SKATES'">
                            <div v-if="item.skateLength" >
                                <span class="text--secondary">Izmērs: </span>
                                {{ item.skateLength + ' - EUR ' + sizes.SKATES.LENGTH[item.skateLength].EUR }}
                            </div>
                            <div v-if="item.skateWidth" >
                                <span class="text--secondary">Platums: </span>
                                {{ item.skateWidth }}
                            </div>
                        </div>
                        <div v-else-if="item.category === 'STICKS'">
                            <div v-if="item.stickSize" >
                                <span class="text--secondary">Nūjas izmērs: </span>
                                {{ item.stickSize }}
                            </div>
                            <div v-if="item.bladeCurve" >
                                <span class="text--secondary">Lāpstiņa: </span>
                                {{ item.bladeCurve }}
                            </div>
                            <div v-if="item.stickFlex" >
                                <span class="text--secondary">Flex: </span>
                                {{ item.stickFlex }}
                            </div>
                        </div>
                        <div v-else-if="item.category === 'PANTS'
                            || item.category === 'SHOULDER_PADS'
                            || item.category === 'ELBOW_PADS'"
                        >
                            <div v-if="item.size" >
                                <span class="text--secondary">Izmērs: </span>
                                {{ sizes.PANTS_SHOULDER_ELBOW_PAD_SIZES[item.size] }}
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="item.size" >
                                <span class="text--secondary">Izmērs: </span>
                                {{ item.size }}
                            </div>
                        </div>
                        <div v-if="item.description" >
                            <span class="text--secondary">Apraksts: </span>
                            {{ item.description }}
                        </div>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </div>

        <div v-else>
            <v-card-text v-if="authenticated" class="text-right">
                <div v-if="!item.isActive" class="grey--text text-left">Neaktīvs</div>

                <v-btn
                    color="warning"
                    class="mx-2"
                    fab
                    small
                    dark
                    :to="'/editOffer/' + item.id"
                    :loading="loading" :disabled="loading"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                    v-bind:color="item.isActive ? 'error' : 'primary'"
                    fab
                    small
                    dark
                    v-on:click.prevent="markOffer(item.id)"
                    :loading="loading" :disabled="loading"
                >
                    <v-icon>{{ item.isActive ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
            </v-card-text>

            <v-carousel
                v-if="item.images.length > 0"
                cycle
                height="auto"
                hide-delimiter-background
                show-arrows-on-hover
                hide-delimiters
            >
                <v-carousel-item
                    v-for="(image, i) in item.images"
                    :key="i"
                >
                    <v-img
                        :src="image.path + image.name"
                        contain
                        max-height="400"
                    ></v-img>
                </v-carousel-item>
            </v-carousel>

            <v-card-title style="word-break: break-word;">{{ item.title }}</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>{{ item.description }}</v-card-text>
        </div>
    </v-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Alert from './popups/Alert';
    export default {
        props: [ 'item', 'isForListing' ],

        components: {
            Alert,
        },

        data: () => ({
            loading: false,
            show: false,
            errors: {
                message: null,
                visible: false,
            },
        }),

        computed: {
            ...mapGetters({
                authenticated: 'auth/authenticated',
                categories: 'enums/categories',
                states: 'enums/states',
                brands: 'enums/brands',
                sizes: 'enums/sizes',
            }),
        },

        methods: {
            ...mapActions({
                getItemListAction: 'items/getItems',
                getOffersListAction: 'offers/getOffers',
                setMessageAction: 'message/setMessage',
            }),

            getState(state) {
                return this.states[state];
            },

            markItem(id) {
                this.loading = true;
                axios.get('/api/markItem/' + id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                        this.getItemListAction();
                    } else {
                        this.errors.message = response.data.message;
                        this.errors.visible = true;
                        this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                    }
                    this.resetMessageTimeout(10000);
                }).catch((error) => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
            },

            markOffer(id) {
                this.loading = true;
                axios.get('/api/markOffer/' + id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                        this.getOffersListAction();
                    } else {
                        this.errors.message = response.data.message;
                        this.errors.visible = true;
                        this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                    }
                    this.resetMessageTimeout(10000);
                }).catch((error) => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
            },

            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
        },
    }
</script>

<style scoped>

</style>
