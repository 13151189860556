<template>
    <GmapMap
        :zoom="zoom"
        :center="center"
        style="width:100%;  height: 300px;"
    >
        <GmapMarker
            :position="center"
        />
    </GmapMap>
</template>

<script>
    export default {
        data: () => ({
            center: {
                lat: 56.96249283337657,
                lng: 24.137934280871974,
            },
            zoom: 12,
        }),
    };
</script>