<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-5>
                <Alert v-if="errors.visible" :error="errors.message" />
                <h1>Mainīt paroli</h1>
            </v-flex>

            <v-flex xs12 sm6 offset-sm3 mt-3>
                <form @submit.prevent="newPass">
                    <v-layout column>
                        <v-flex>
                            <v-text-field
                                name="currentPassword"
                                v-model="form.currentPassword"
                                id="currentPassword"
                                label="Esošā Parole"
                                type="password"
                                :error-messages="currentPasswordErrors"
                                required
                                @input="$v.form.currentPassword.$touch()"
                                @blur="$v.form.currentPassword.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                name="newPassword"
                                v-model="form.newPassword"
                                id="newPassword"
                                label="Jaunā Parole"
                                type="password"
                                :error-messages="newPasswordErrors"
                                required
                                @input="$v.form.newPassword.$touch()"
                                @blur="$v.form.newPassword.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                name="newPassword_confirmation"
                                v-model="form.newPassword_confirmation"
                                id="newPassword_confirmation"
                                label="Jaunā Parole Atkārtoti"
                                type="password"
                                :error-messages="confirmNewPasswordErrors"
                                required
                                @input="$v.form.newPassword_confirmation.$touch()"
                                @blur="$v.form.newPassword_confirmation.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex mt-5>
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                small
                            >
                                Mainīt
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, maxLength, minLength, sameAs, not } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';

    export default {
        mixins: [ validationMixin ],

        validations: {
            form: {
                currentPassword: { required, minLength: minLength(8), maxLength: maxLength(1024),
                    isPasswordCorrect(value) {
                        if (value === '') return true;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(this.isPasswordCorrect());
                            }, 1000);
                        });
                    }
                },
                newPassword: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(1024),
                    notSameAsCurrentPassword: not(sameAs('currentPassword'))
                },
                newPassword_confirmation: { required, minLength: minLength(8), sameAsPassword: sameAs('newPassword') },
            },
        },

        components: {
            Alert,
        },

        data: () => ({
            form: {
                id: null,
                currentPassword: '',
                newPassword: '',
                newPassword_confirmation: '',
                recaptcha: '',
            },
            errors: {
                currentPassword: null,
                newPassword: null,
                message: null,
                visible: false,
            },
            loading: false,
            newImage: { title: 'Jauna bilde', icon: 'mdi-plus' },
        }),

        computed: {
            currentPasswordErrors() {
                const errors = [];
                if (this.errors.currentPassword) {
                    errors.push(this.errors.currentPassword[0]);
                    this.errors.currentPassword = null;
                }
                if (!this.$v.form.currentPassword.$dirty) return errors;
                !this.$v.form.currentPassword.required && errors.push('Obligāts lauks');
                !this.$v.form.currentPassword.minLength && errors.push('Jābūt vismaz 8 rakstzīmēm garam');
                !this.$v.form.currentPassword.maxLength && errors.push('Nedrīkst pārsniegt 1024 rakstzīmes');
                !this.$v.form.currentPassword.isPasswordCorrect && errors.push('Parole nav korekta!');
                return errors;
            },
            newPasswordErrors() {
                const errors = [];
                if (this.errors.newPassword) {
                    errors.push(this.errors.newPassword[0]);
                    this.errors.newPassword = null;
                }
                if (!this.$v.form.newPassword.$dirty) return errors;
                !this.$v.form.newPassword.required && errors.push('Obligāts lauks');
                !this.$v.form.newPassword.minLength && errors.push('Jābūt vismaz 8 rakstzīmēm garam');
                !this.$v.form.newPassword.maxLength && errors.push('Nedrīkst pārsniegt 1024 rakstzīmes');
                !this.$v.form.newPassword.notSameAsCurrentPassword && errors.push('Nedrīkst būt vienāda ar esošo!');
                return errors;
            },
            confirmNewPasswordErrors() {
                const errors = [];
                if (!this.$v.form.newPassword_confirmation.$dirty) return errors;
                !this.$v.form.newPassword_confirmation.required && errors.push('Obligāts lauks');
                !this.$v.form.newPassword_confirmation.minLength && errors.push('Jābūt vismaz 8 rakstzīmēm garam');
                !this.$v.form.newPassword_confirmation.sameAsPassword && errors.push('Paroles nesakrīt!');
                return errors;
            },
            ...mapGetters({
                user: 'auth/user',
            }),
        },

        methods: {
            ...mapActions({
                refreshUserDataAction: 'auth/login',
                setMessageAction: 'message/setMessage',
            }),

            newPass() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.errors.message = 'Dati satur kļūdas.';
                    this.errors.visible = true;
                    this.resetMessageTimeout(10000);
                    this.setMessageAction( { message: 'Kļūda!', isVisible: true, type: 'error' } );
                } else {
                    this.loading = true;
                    this.$recaptcha('newPass').then(token => {
                        this.form.recaptcha = token;
                        this.form.id = this.user.id;
                        axios.post('/api/newPass', this.form).then(response => {
                            this.loading  = false;
                            this.form.recaptcha = '';
                            this.form.id = '';
                            if (response.data.success) {
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                                this.refreshUserDataAction();
                                this.$router.push({ name: 'Account' });
                            } else {
                                this.errors.message = response.data.message;
                                this.errors.visible = true;
                                this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );

                                if (response.data.errors) {
                                    if (response.data.errors.currentPassword) {
                                        this.errors.currentPassword = response.data.errors.currentPassword;
                                    }
                                    if (response.data.errors.newPassword) {
                                        this.errors.newPassword = response.data.errors.newPassword;
                                    }
                                }
                            }
                            this.resetMessageTimeout(10000);
                        }).catch((error) => {
                            this.loading = true;
                            this.form.recaptcha = '';
                            this.form.id = '';
                            this.setMessageAction( {
                                message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                isVisible: true,
                                type: 'error'
                            } );
                        });
                    });
                }
            },

            async isPasswordCorrect() {
                if (this.form.currentPassword === '') return true;
                this.form.id = this.user.id;
                const response = await axios.post('/api/checkPasswordMatch', this.form).then((response) => {
                    this.form.id = '';
                    return response.data.match;
                }).catch(() => {
                    this.loading = true;
                    this.form.id = '';
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
                return Boolean(await response);
            },

            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
        },
    }
</script>

<style scoped>

</style>
