<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-5>
                <Alert v-if="errors.visible" :error="errors.message" />

                <h1>Pievienot jaunu preci</h1>
            </v-flex>
            <v-flex xs12 sm6 offset-sm3 mt-3>
                <form @submit.prevent="addItem">
                    <v-layout column>
                        <v-flex>
                            <v-text-field
                                name="title"
                                v-model="form.title"
                                id="title"
                                label="Nosaukums"
                                type="text"
                                :error-messages="titleErrors"
                                :counter="256"
                                required
                                @input="$v.form.title.$touch()"
                                @blur="$v.form.title.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-textarea
                                solo
                                name="description"
                                v-model="form.description"
                                id="description"
                                label="Apraksts"
                                :error-messages="descriptionErrors"
                                @input="$v.form.description.$touch()"
                                @blur="$v.form.description.$touch()"
                            ></v-textarea>
                        </v-flex>

                        <v-flex>
                            <v-autocomplete
                                name="brand"
                                v-model="form.brand"
                                id="brand"
                                label="Zīmols"
                                :items="getItems(brands)"
                                placeholder="Izvēlies zīmolu..."
                                :error-messages="brandErrors"
                                :auto-select-first="true"
                                @input="$v.form.brand.$touch()"
                                @blur="$v.form.brand.$touch()"
                            ></v-autocomplete>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                name="price"
                                v-model="form.price"
                                id="price"
                                label="Cena"
                                prefix="€"
                                :error-messages="priceErrors"
                                @input="$v.form.price.$touch()"
                                @blur="$v.form.price.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-autocomplete
                                name="state"
                                v-model="form.state"
                                id="state"
                                label="Stāvoklis"
                                :items="getItems(states)"
                                placeholder="Izvēlies stāvokli..."
                                :error-messages="stateErrors"
                                :auto-select-first="true"
                                required
                                @input="$v.form.state.$touch()"
                                @blur="$v.form.state.$touch()"
                            ></v-autocomplete>
                        </v-flex>

                        <v-flex>
                            <v-autocomplete
                                name="category"
                                v-model="form.category"
                                id="category"
                                label="Kategorija"
                                :items="getItems(categories)"
                                placeholder="Izvēlies kategoriju..."
                                :error-messages="categoryErrors"
                                :auto-select-first="true"
                                required
                                @input="$v.form.category.$touch()"
                                @blur="$v.form.category.$touch()"
                                @change="resetSize()"
                            ></v-autocomplete>
                        </v-flex>

                        <div v-if="form.category === 'Slidas'">
                            <v-flex>
                                <v-autocomplete
                                    name="skateLength"
                                    v-model="form.skateLength"
                                    id="skateLength"
                                    label="Izmērs"
                                    :items="getSkateSizes()"
                                    placeholder="Izvēlies izmēru..."
                                    :error-messages="skateLengthErrors"
                                    :auto-select-first="true"
                                    @input="$v.form.skateLength.$touch()"
                                    @blur="$v.form.skateLength.$touch()"
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex>
                                <v-autocomplete
                                    name="skateWidth"
                                    v-model="form.skateWidth"
                                    id="skateWidth"
                                    label="Platums"
                                    :items="sizes.SKATES.WIDTH"
                                    placeholder="Izvēlies platumu..."
                                    :error-messages="skateWidthErrors"
                                    :auto-select-first="true"
                                    @input="$v.form.skateWidth.$touch()"
                                    @blur="$v.form.skateWidth.$touch()"
                                ></v-autocomplete>
                            </v-flex>
                        </div>
                        <div v-else-if="form.category === 'Nūjas'">
                            <v-flex>
                                <v-autocomplete
                                    name="stickSize"
                                    v-model="form.stickSize"
                                    id="stickSize"
                                    label="Nūjas izmērs"
                                    :items="sizes.STICK.SIZE"
                                    placeholder="Izvēlies nūjas izmēru..."
                                    :error-messages="stickSizeErrors"
                                    :auto-select-first="true"
                                    @input="$v.form.stickSize.$touch()"
                                    @blur="$v.form.stickSize.$touch()"
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex>
                                <v-autocomplete
                                    name="bladeCurve"
                                    v-model="form.bladeCurve"
                                    id="bladeCurve"
                                    label="Lāpstiņas izmērs"
                                    :items="sizes.STICK.BLADE_CURVE"
                                    placeholder="Izvēlies lāpstiņas izmēru..."
                                    :error-messages="bladeCurveErrors"
                                    :auto-select-first="true"
                                    @input="$v.form.bladeCurve.$touch()"
                                    @blur="$v.form.bladeCurve.$touch()"
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex>
                                <v-autocomplete
                                    name="stickFlex"
                                    v-model="form.stickFlex"
                                    id="stickFlex"
                                    label="Flex"
                                    :items="sizes.STICK.FLEX"
                                    placeholder="Izvēlies nūjas flex..."
                                    :error-messages="stickFlexErrors"
                                    :auto-select-first="true"
                                    required
                                    @input="$v.form.stickFlex.$touch()"
                                    @blur="$v.form.stickFlex.$touch()"
                                ></v-autocomplete>
                            </v-flex>
                        </div>
                        <div v-else>
                            <v-flex>
                                <v-combobox
                                    name="size"
                                    v-model="form.size"
                                    id="size"
                                    label="Izmērs"
                                    :items="getSizes()"
                                    placeholder="Izvēlies izmēru..."
                                    :error-messages="sizeErrors"
                                    :auto-select-first="true"
                                    @input="$v.form.size.$touch()"
                                    @blur="$v.form.size.$touch()"
                                ></v-combobox>
                            </v-flex>
                        </div>

                        <v-flex>
                            <v-checkbox
                                name="isActive"
                                v-model="form.isActive"
                                id="isActive"
                                color="primary"
                                :error-messages="isActiveErrors"
                                @input="$v.form.isActive.$touch()"
                                @blur="$v.form.isActive.$touch()"
                            >
                                <template v-slot:label>Prece ir aktīva?</template>
                            </v-checkbox>
                        </v-flex>

                        <v-divider></v-divider>

                        <v-layout
                            v-for="(image, i) in $v.form.images.$each.$iter"
                            :key="i"
                        >
                            <v-col cols="6">
                                <v-file-input
                                    v-model="image.data.$model"
                                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                                    placeholder="Izvēlies bildi..."
                                    prepend-icon="mdi-camera"
                                    label="Bilde"
                                    @change="createImage(i)"
                                    @click:clear="deleteImage(i)"
                                    :error-messages="validateImage(image.data, i)"
                                    clearable
                                ></v-file-input>
                            </v-col>

                            <v-col cols="6">
                                <v-img
                                    max-height="300"
                                    :src="form.images[i].preview"
                                ></v-img>
                            </v-col>
                        </v-layout>

                        <v-flex my-5>
                            <v-btn
                                dark
                                small
                                color="success"
                                v-on:click="addImage"
                            >
                                <v-icon dark>
                                    {{ newImage.icon }}
                                </v-icon>
                                {{ newImage.title }}
                            </v-btn>
                        </v-flex>

                        <v-divider></v-divider>

                        <v-flex mt-5>
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                small
                            >
                                Pievienot
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, maxLength, minLength, decimal, minValue, maxValue } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';
    export default {
        mixins: [ validationMixin ],

        validations: {
            form: {
                title: { required, minLength: minLength(3), maxLength: maxLength(256) },
                description: { maxLength: maxLength(4096) },
                price: { decimal, minValue: minValue(0.00), maxValue: maxValue(2000000000.00) },
                category: { required },
                isActive: { },
                state: { required },
                brand: { },
                size: { },
                stickFlex: { },
                bladeCurve: { },
                stickSize: { },
                skateLength: { },
                skateWidth: { },
                images: {
                    $each: {
                        data: {
                            isFileSize(value) {
                                if (!value || value === '' || value === undefined) return true;
                                return (value.size < 5000000);
                            }
                        },
                        base64: { },
                        preview: { },
                    }
                },
            },
        },

        components: {
            Alert,
        },

        data: () => ({
            form: {
                title: '',
                description: '',
                brand: '',
                price: 100.00,
                state: 'Jauns',
                category: '',
                size: '',
                stickFlex: '',
                bladeCurve: '',
                stickSize: '',
                skateLength: '',
                skateWidth: '',
                isActive: true,
                images: [],
                recaptcha: '',
            },
            errors: {
                title: null,
                description: null,
                brand: null,
                price: null,
                state: null,
                category: null,
                size: null,
                stickFlex: null,
                bladeCurve: null,
                stickSize: null,
                skateLength: null,
                skateWidth: null,
                isActive: null,
                images: null,
                message: null,
                visible: false,
            },
            loading: false,
            newImage: { title: 'Jauna bilde', icon: 'mdi-plus' },
        }),

        computed: {
            titleErrors() {
                const errors = [];
                if (this.errors.title) {
                    errors.push(this.errors.title[0]);
                    this.errors.title = null;
                }
                if (!this.$v.form.title.$dirty) return errors;
                !this.$v.form.title.maxLength && errors.push('Nedrīkst pārsniegt 256 rakstzīmes');
                !this.$v.form.title.minLength && errors.push('Jābūt vismaz 3 rakstzīmēm');
                !this.$v.form.title.required && errors.push('Obligāts lauks');
                return errors;
            },
            descriptionErrors() {
                const errors = [];
                if (this.errors.description) {
                    errors.push(this.errors.description[0]);
                    this.errors.description = null;
                }
                if (!this.$v.form.description.$dirty) return errors;
                !this.$v.form.description.maxLength && errors.push('Nedrīkst pārsniegt 4096 rakstzīmes');
                return errors;
            },
            priceErrors() {
                const errors = [];
                if (this.errors.price) {
                    errors.push(this.errors.price[0]);
                    this.errors.price = null;
                }
                if (!this.$v.form.price.$dirty) return errors;
                !this.$v.form.price.decimal && errors.push('Drīkst saturēt tikai naturālus skaitļus vai skaitļus ar punktu');
                !this.$v.form.price.minValue && errors.push('Jābūt pozitīvai vērtībai');
                !this.$v.form.price.maxValue && errors.push('Pārāk liela vērtība');
                return errors;
            },
            categoryErrors() {
                const errors = [];
                if (this.errors.category) {
                    errors.push(this.errors.category[0]);
                    this.errors.category = null;
                }
                if (!this.$v.form.category.$dirty) return errors;
                !this.$v.form.category.required && errors.push('Obligāts lauks');
                return errors;
            },
            isActiveErrors() {
                const errors = [];
                if (this.errors.isActive) {
                    errors.push(this.errors.isActive[0]);
                    this.errors.isActive = null;
                }
                if (!this.$v.form.isActive.$dirty) return errors;
                return errors;
            },
            stateErrors() {
                const errors = [];
                if (this.errors.state) {
                    errors.push(this.errors.state[0]);
                    this.errors.state = null;
                }
                if (!this.$v.form.state.$dirty) return errors;
                !this.$v.form.state.required && errors.push('Obligāts lauks');
                return errors;
            },
            brandErrors() {
                const errors = [];
                if (this.errors.brand) {
                    errors.push(this.errors.brand[0]);
                    this.errors.brand = null;
                }
                if (!this.$v.form.brand.$dirty) return errors;
                return errors;
            },
            skateLengthErrors() {
                const errors = [];
                if (this.errors.skateLength) {
                    errors.push(this.errors.skateLength[0]);
                    this.errors.skateLength = null;
                }
                if (!this.$v.form.skateLength.$dirty) return errors;
                return errors;
            },
            skateWidthErrors() {
                const errors = [];
                if (this.errors.skateWidth) {
                    errors.push(this.errors.skateWidth[0]);
                    this.errors.skateWidth = null;
                }
                if (!this.$v.form.skateWidth.$dirty) return errors;
                return errors;
            },
            stickSizeErrors() {
                const errors = [];
                if (this.errors.stickSize) {
                    errors.push(this.errors.stickSize[0]);
                    this.errors.stickSize = null;
                }
                if (!this.$v.form.stickSize.$dirty) return errors;
                return errors;
            },
            bladeCurveErrors() {
                const errors = [];
                if (this.errors.bladeCurve) {
                    errors.push(this.errors.bladeCurve[0]);
                    this.errors.bladeCurve = null;
                }
                if (!this.$v.form.bladeCurve.$dirty) return errors;
                return errors;
            },
            stickFlexErrors() {
                const errors = [];
                if (this.errors.stickFlex) {
                    errors.push(this.errors.stickFlex[0]);
                    this.errors.stickFlex = null;
                }
                if (!this.$v.form.stickFlex.$dirty) return errors;
                return errors;
            },
            sizeErrors() {
                const errors = [];
                if (this.errors.size) {
                    errors.push(this.errors.size[0]);
                    this.errors.size = null;
                }
                if (!this.$v.form.size.$dirty) return errors;
                return errors;
            },
            ...mapGetters({
                categories: 'enums/categories',
                states: 'enums/states',
                brands: 'enums/brands',
                sizes: 'enums/sizes',
            }),
        },

        methods: {
            ...mapActions({
                getItemListAction: 'items/getItems',
                setMessageAction: 'message/setMessage',
            }),
            addItem() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.errors.message = 'Dati satur kļūdas.';
                    this.errors.visible = true;
                    this.resetMessageTimeout(10000);
                    this.setMessageAction( { message: 'Kļūda!', isVisible: true, type: 'error' } );
                } else {
                    this.loading = true;
                    this.removeEmptyImages();
                    this.$recaptcha('addItem').then(token => {
                        this.form.recaptcha = token;
                        axios.post('/api/storeItem', this.form).then(response => {
                            this.loading  = false;
                            this.form.recaptcha = '';
                            if (response.data.success) {
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                                this.getItemListAction();
                                this.$router.push({ name: 'Adverts' });
                            } else {
                                this.errors.message = response.data.message;
                                this.errors.visible = true;
                                this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );

                                if (response.data.errors) {
                                    if (response.data.errors.title) {
                                        this.errors.title = response.data.errors.title;
                                    }
                                    if (response.data.errors.description) {
                                        this.errors.description = response.data.errors.description;
                                    }
                                    if (response.data.errors.price) {
                                        this.errors.price = response.data.errors.price;
                                    }
                                    if (response.data.errors.category) {
                                        this.errors.category = response.data.errors.category;
                                    }
                                    if (response.data.errors.isActive) {
                                        this.errors.isActive = response.data.errors.isActive;
                                    }
                                    if (response.data.errors.state) {
                                        this.errors.state = response.data.errors.state;
                                    }
                                    if (response.data.errors.brand) {
                                        this.errors.brand = response.data.errors.brand;
                                    }
                                    if (response.data.errors.size) {
                                        this.errors.size = response.data.errors.size;
                                    }
                                    if (response.data.errors.stickFlex) {
                                        this.errors.stickFlex = response.data.errors.stickFlex;
                                    }
                                    if (response.data.errors.bladeCurve) {
                                        this.errors.bladeCurve = response.data.errors.bladeCurve;
                                    }
                                    if (response.data.errors.stickSize) {
                                        this.errors.stickSize = response.data.errors.stickSize;
                                    }
                                    if (response.data.errors.skateLength) {
                                        this.errors.skateLength = response.data.errors.skateLength;
                                    }
                                    if (response.data.errors.skateWidth) {
                                        this.errors.skateWidth = response.data.errors.skateWidth;
                                    }
                                    if (response.data.errors.images && response.data.errors.images.length > 0) {
                                        this.errors.images = response.data.errors.images;
                                    }
                                }
                            }
                            this.resetMessageTimeout(10000);
                        }).catch((error) => {
                            this.loading = true;
                            this.form.recaptcha = '';
                            this.setMessageAction( {
                                message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                isVisible: true,
                                type: 'error'
                            } );
                        });
                    });
                }
            },
            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
            getItems(object) {
                return Object.values(object);
            },
            addImage() {
                this.form.images.push( { data: null, base64: '', preview: '' } );
            },
            createImage(index) {
                if (this.form.images.length === 0
                    || !this.form.images[index]
                    || !this.form.images[index].data
                ) {
                    return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.form.images[index].base64 = e.target.result;
                };
                reader.readAsDataURL(this.form.images[index].data);
                this.form.images[index].preview = URL.createObjectURL(this.form.images[index].data);
            },
            deleteImage(index) {
                if (this.form.images.length === 0 || !this.form.images[index]) {
                    return;
                }
                this.form.images.splice(index, 1);
            },
            validateImage(item, index) {
                const errors = [];
                if (this.errors.images
                    && this.errors.images.length > 0
                    && this.errors.images[index]
                ) {
                    errors.push(this.errors.images[index][0]);
                    this.errors.images[index] = null;
                }
                if (!item.$dirty) return errors;
                !item.isFileSize && errors.push('Bildes izmēram jābūt mazākam par 5 MB');
                return errors;
            },
            removeEmptyImages() {
                this.form.images = this.form.images.filter(image => {
                    return image.base64 && image.base64 !== '' && image.base64 !== undefined
                });
            },
            getSizes() {
                if (this.form.category === 'Ķiveres') {
                    return this.sizes.HELMET;
                } else if (this.form.category === 'Cimdi') {
                    return this.sizes.GLOVES;
                } else if (this.form.category === 'Bikses'
                    || this.form.category === 'Plecu sargi'
                    || this.form.category === 'Elkoņu sargi'
                ) {
                    return Object.values(this.sizes.PANTS_SHOULDER_ELBOW_PAD_SIZES);
                } else if (this.form.category === 'Kāju sargi') {
                    return this.sizes.SHIN_GUARD;
                } else if (this.form.category === 'Somas') {
                    return this.sizes.BAG;
                } else if (this.form.category === 'Komplekti') {
                    return this.sizes.KIT;
                } else {
                    return [];
                }
            },
            getSkateSizes() {
                const skateSizes = [ ];
                Object.entries(this.sizes.SKATES.LENGTH).forEach(entry => {
                    const [key, value] = entry;
                    skateSizes.push( key + ' - EUR ' + value.EUR );
                });

                return skateSizes;
            },
            resetSize() {
                this.form.size = '';
            },
        },
    }
</script>

<style scoped>

</style>
