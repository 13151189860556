<template>
    <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-3 mb-12"
        :loading="loading"
        :items-per-page="-1"
    >
    </v-data-table>
</template>

<script>
    export default {
        props: [ 'headers', 'items', 'loading' ],
    }
</script>

<style scoped>

</style>
