<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-5>
                <Alert v-if="errors.visible" :error="errors.message" />
                <h1>Pieslēgties</h1>
            </v-flex>
            <v-flex xs12 sm6 offset-sm3 mt-3>
                <form @submit.prevent="signin">
                <v-layout column>
                    <v-flex>
                        <v-text-field
                            name="email"
                            v-model="form.email"
                            id="email"
                            label="Epasts"
                            type="email"
                            :error-messages="emailErrors"
                            required
                            @input="$v.form.email.$touch()"
                            @blur="$v.form.email.$touch()"
                            ></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field
                            name="password"
                            v-model="form.password"
                            id="password"
                            label="Parole"
                            type="password"
                            :error-messages="passwordErrors"
                            required
                            @input="$v.form.password.$touch()"
                            @blur="$v.form.password.$touch()"
                            ></v-text-field>
                    </v-flex>
                    <v-flex class="text-xs-center" mt-5>
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="loading"
                            :disabled="loading"
                            small
                        >
                            Pieslēgties
                        </v-btn>
                    </v-flex>
                </v-layout>
                </form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, email } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';
    export default {
        name: 'login',

        mixins: [validationMixin],

        validations: {
            form: {
                email: { required, email },
                password: { required },
            },
        },

        components: {
            Alert,
        },

        data: () => ({
            form: {
                email: '',
                password: '',
                recaptcha: '',
            },
            errors: {
                message: null,
                visible: false,
            },
            loading: false,
        }),

        computed: {
            emailErrors () {
                const errors = []
                if (!this.$v.form.email.$dirty) return errors
                !this.$v.form.email.email && errors.push('Nederīgs e-pasts')
                !this.$v.form.email.required && errors.push('Obligāts lauks')
                return errors
            },
            passwordErrors () {
                const errors = []
                if (!this.$v.form.password.$dirty) return errors
                !this.$v.form.password.required && errors.push('Obligāts lauks')
                return errors
            },
        },

        methods: {
            ...mapActions({
                signInAction: 'auth/login',
                setMessageAction: 'message/setMessage',
            }),

            signin() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.errors.message = 'Dati satur kļūdas.';
                    this.errors.visible = true;
                    this.resetMessageTimeout(10000);
                    this.setMessageAction( { message: 'Kļūda!', isVisible: true, type: 'error' } );
                } else {
                    this.loading = true;
                    this.$recaptcha('login').then(token => {
                        this.form.recaptcha = token;
                        axios.get('/sanctum/csrf-cookie').then(response => {
                            axios.post('/api/login', this.form).then(response => {
                                this.loading  = false;
                                this.form.recaptcha = '';
                                if (response.data.success) {
                                    this.setMessageAction( { message: 'Autorizācija veiksmīga!', isVisible: true, type: 'success' } );
                                    this.signInAction();
                                    this.$router.push({ name: 'Home' });
                                } else {
                                    this.errors.message = response.data.message;
                                    this.errors.visible = true;
                                    this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                                }
                                this.resetMessageTimeout(10000);
                            }).catch((error) => {
                                this.loading = true;
                                this.form.recaptcha = '';
                                this.setMessageAction( {
                                    message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                    isVisible: true,
                                    type: 'error'
                                } );
                            });
                        });
                    });
                }
            },

            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
        },
    }
</script>

<style scoped>

</style>
