<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-5>
                <Alert v-if="errors.visible" :error="errors.message" />
                <h1>Reģistrēt jaunu lietotāju</h1>
            </v-flex>
            <v-flex xs12 sm6 offset-sm3 mt-3>
                <form @submit.prevent="register">
                <v-layout column>
                    <v-flex>
                        <v-text-field
                            name="username"
                            v-model="form.username"
                            id="username"
                            label="Lietotājvārds"
                            type="text"
                            :error-messages="usernameErrors"
                            :counter="64"
                            required
                            @input="$v.form.username.$touch()"
                            @blur="$v.form.username.$touch()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field
                            name="email"
                            v-model="form.email"
                            id="email"
                            label="Epasts"
                            type="email"
                            :error-messages="emailErrors"
                            required
                            @input="$v.form.email.$touch()"
                            @blur="$v.form.email.$touch()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field
                            name="password"
                            v-model="form.password"
                            id="password"
                            label="Parole"
                            type="password"
                            :error-messages="passwordErrors"
                            required
                            @input="$v.form.password.$touch()"
                            @blur="$v.form.password.$touch()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field
                            name="password_confirmation"
                            v-model="form.password_confirmation"
                            id="password_confirmation"
                            label="Parole Atkārtoti"
                            type="password"
                            :error-messages="confirmPasswordErrors"
                            required
                            @input="$v.form.password_confirmation.$touch()"
                            @blur="$v.form.password_confirmation.$touch()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-checkbox
                            name="isConfirmed"
                            v-model="form.isConfirmed"
                            id="isConfirmed"
                            color="primary"
                            :error-messages="isConfirmedErrors"
                            @input="$v.form.isConfirmed.$touch()"
                            @blur="$v.form.isConfirmed.$touch()"
                        >
                            <template v-slot:label>Ir aktīvs?</template>
                        </v-checkbox>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex>
                        <v-autocomplete
                            name="roles"
                            v-model="form.roles"
                            id="roles"
                            label="Atļautās lomas"
                            :items="getVisibleRoles"
                            placeholder="Izvēlies lomas..."
                            :error-messages="roleErrors"
                            :auto-select-first="true"
                            multiple
                            small-chips
                            @input="$v.form.roles.$touch()"
                            @blur="$v.form.roles.$touch()"
                        ></v-autocomplete>
                    </v-flex>
                    <v-flex mt-5>
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="loading"
                            :disabled="loading"
                            small
                        >
                            Reģistrēties
                        </v-btn>
                    </v-flex>
                </v-layout>
                </form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, maxLength, minLength, email, sameAs } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';

    export default {
        mixins: [ validationMixin ],

        validations: {
            form: {
                username: { required, minLength: minLength(3), maxLength: maxLength(64),
                    isUnique(value) {
                        if (value === '') return true;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(this.isUsernameAvailable());
                            }, 1000);
                        });
                    }
                },
                email: { required, email, maxLength: maxLength(256),
                    isUnique(value) {
                        if (value === '') return true;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(this.isEmailAvailable());
                            }, 1000);
                        });
                    }
                },
                password: { required, minLength: minLength(8), maxLength: maxLength(1024) },
                password_confirmation: { required, minLength: minLength(8), sameAsPassword: sameAs('password') },
                isConfirmed: { },
                roles: { },
            },
        },

        components: {
            Alert,
        },

        data: () => ({
            form: {
                username: '',
                email: '',
                password: '',
                password_confirmation: '',
                isConfirmed: true,
                roles: [ ],
                recaptcha: '',
            },
            errors: {
                username: null,
                email: null,
                password: null,
                isConfirmed: null,
                roles: null,
                message: null,
                visible: false,
            },
            loading: false,
        }),

        computed: {
            usernameErrors() {
                const errors = [];
                if (this.errors.username) {
                    errors.push(this.errors.username[0]);
                    this.errors.username = null;
                }
                if (!this.$v.form.username.$dirty) return errors;
                !this.$v.form.username.maxLength && errors.push('Nedrīkst pārsniegt 64 rakstzīmes');
                !this.$v.form.username.minLength && errors.push('Jābūt vismaz 3 rakstzīmēm');
                !this.$v.form.username.required && errors.push('Obligāts lauks');
                !this.$v.form.username.isUnique && errors.push('Lietotājvārds jau pastāv');
                return errors;
            },
            emailErrors() {
                const errors = [];
                if (this.errors.email) {
                    errors.push(this.errors.email[0]);
                    this.errors.email = null;
                }
                if (!this.$v.form.email.$dirty) return errors;
                !this.$v.form.email.email && errors.push('Nederīgs e-pasts');
                !this.$v.form.email.maxLength && errors.push('Nedrīkst pārsniegt 256 rakstzīmes');
                !this.$v.form.email.required && errors.push('Obligāts lauks');
                !this.$v.form.email.isUnique && errors.push('Epasts jau pastāv');
                return errors;
            },
            passwordErrors() {
                const errors = [];
                if (this.errors.password) {
                    errors.push(this.errors.password[0]);
                    this.errors.password = null;
                }
                if (!this.$v.form.password.$dirty) return errors;
                !this.$v.form.password.required && errors.push('Obligāts lauks');
                !this.$v.form.password.minLength && errors.push('Jābūt vismaz 8 rakstzīmēm garam');
                !this.$v.form.password.maxLength && errors.push('Nedrīkst pārsniegt 1024 rakstzīmes');
                return errors;
            },
            confirmPasswordErrors() {
                const errors = [];
                if (!this.$v.form.password_confirmation.$dirty) return errors;
                !this.$v.form.password_confirmation.required && errors.push('Obligāts lauks');
                !this.$v.form.password_confirmation.minLength && errors.push('Jābūt vismaz 8 rakstzīmēm garam');
                !this.$v.form.password_confirmation.sameAsPassword && errors.push('Paroles nesakrīt!');
                return errors;
            },
            isConfirmedErrors() {
                const errors = [];
                if (this.errors.isConfirmed) {
                    errors.push(this.errors.isConfirmed[0]);
                    this.errors.isConfirmed = null;
                }
                if (!this.$v.form.isConfirmed.$dirty) return errors;
                return errors;
            },
            roleErrors() {
                const errors = [];
                if (this.errors.roles) {
                    errors.push(this.errors.roles[0]);
                    this.errors.roles = null;
                }
                if (!this.$v.form.roles.$dirty) return errors;
                return errors;
            },
            isSuperAdmin() {
                return this.roles.some(record => record.role === 'superAdmin');
            },
            getVisibleRoles() {
                if (this.allRoles && this.allRoles.length) {
                    return this.allRoles.filter(item =>
                        this.isSuperAdmin
                            || (item !== 'superAdmin'
                                && item !== 'editUser'
                                && item !== 'markUser'
                                && item !== 'enterAdminCp')
                    );
                } else {
                    return [];
                }
            },
            ...mapGetters({
                allRoles: 'auth/allRoles',
                roles: 'auth/roles',
            }),
        },

        methods: {
            ...mapActions({
                setMessageAction: 'message/setMessage',
                getAllRolesListAction: 'auth/getAllRoles',
            }),
            register() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.errors.message = 'Dati satur kļūdas.';
                    this.errors.visible = true;
                    this.setMessageAction( { message: 'Kļūda!', isVisible: true, type: 'error' } );
                    this.resetMessageTimeout(10000);
                } else {
                    this.loading = true;
                    this.$recaptcha('register').then(token => {
                        this.form.recaptcha = token;
                        axios.post('/api/storeUser', this.form).then((response) => {
                            this.loading  = false;
                            this.form.recaptcha = '';
                            if (response.data.success) {
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                                this.$router.push({ name: 'AdminControlPanel' });
                            } else {
                                this.errors.message = response.data.message;
                                this.errors.visible = true;
                                this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );

                                if (response.data.errors) {
                                    if (response.data.errors.username) {
                                        this.errors.username = response.data.errors.username;
                                    }
                                    if (response.data.errors.email) {
                                        this.errors.email = response.data.errors.email;
                                    }
                                    if (response.data.errors.password) {
                                        this.errors.password = response.data.errors.password;
                                    }
                                    if (response.data.errors.isConfirmed) {
                                        this.errors.isConfirmed = response.data.errors.isConfirmed;
                                    }
                                    if (response.data.errors.roles) {
                                        this.errors.roles = response.data.errors.roles;
                                    }
                                }
                            }
                            this.resetMessageTimeout(10000);
                        }).catch((error) => {
                            this.loading = true;
                            this.form.recaptcha = '';
                            this.setMessageAction( {
                                message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                isVisible: true,
                                type: 'error'
                            } );
                        });
                    });
                }
            },
            async isEmailAvailable() {
                if (this.form.email === '') return true;
                const response = await axios.post('/api/checkEmailAvailability', this.form).then((response) => {
                    return response.data.available;
                }).catch(() => {
                    this.loading = true;
                    this.errors.message = 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!';
                    this.errors.visible = true;
                });
                return Boolean(await response);
            },
            async isUsernameAvailable() {
                if (this.form.username === '') return true;
                const response = await axios.post('/api/checkUsernameAvailability', this.form).then((response) => {
                    return response.data.available;
                }).catch(() => {
                    this.loading = true;
                    this.errors.message = 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!';
                    this.errors.visible = true;
                });
                return Boolean(await response);
            },
            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
        },

        created: function() {
            this.getAllRolesListAction();
        },
    }
</script>

<style scoped>

</style>
