<template>
    <v-container fluid grid-list-md >
        <v-layout row wrap>
            <v-col cols="12" md="4">
                <v-flex v-if="visibleItems.length" class="text-center">
                    <h4>
                        Rāda: {{ ((pagination.page - 1) * pagination.perPage) + 1 }} -
                        {{ (pagination.page * pagination.perPage) > filteredAndSortedItems.length ? filteredAndSortedItems.length : (pagination.page * pagination.perPage) }}
                        | Kopā no: {{ filteredAndSortedItems.length }}
                    </h4>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                <v-flex class="text-center">
                    <h1>Preces</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                <v-btn
                    class="hidden-md-and-up float-left mx-2"
                    fab
                    dark
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshData"
                    :loading="isListLoading" :disabled="isListLoading"
                >
                    <v-icon >
                        {{ refreshDataBtn.icon }}
                    </v-icon>
                </v-btn>

                <v-btn
                    class="hidden-sm-and-down float-left mx-2"
                    color="info"
                    elevation="3"
                    outlined
                    v-on:click.prevent="refreshData"
                    :loading="isListLoading" :disabled="isListLoading"
                >
                    {{ refreshDataBtn.title }}
                    <v-icon right>
                        {{ refreshDataBtn.icon }}
                    </v-icon>
                </v-btn>

                <v-btn v-if="authenticated"
                    class="float-right mx-2"
                    fab
                    dark
                    color="success"
                    elevation="3"
                    small
                    :to="newItemBtn.path"
                >
                    <div class="hidden-sm-and-down">{{ newItemBtn.title }}</div>
                    <v-icon dark>
                        {{ newItemBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col v-if="isListLoading" cols="12" class="text-center">
                <v-progress-circular
                    :size="40"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-col>

            <v-col v-else-if="!itemsList.length" cols="12" class="text-center">
                <h3>Nav ierakstu</h3>
            </v-col>

            <v-flex v-else>
                <v-row justify="space-around" class="my-3" no-gutters>
                    <v-col cols="12" md="3" class="pa-2">
                        <v-flex>
                            <v-autocomplete
                                name="perPage"
                                v-model="pagination.perPage"
                                id="perPage"
                                label="Rādīt"
                                :items="perPageList"
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="sortBy"
                                v-model="sortBy"
                                id="sortBy"
                                label="Kārtot pēc"
                                :items="getItems(sortList)"
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="category"
                                v-model="category"
                                id="category"
                                label="Kategorija"
                                :items="getItems(categoriesEnum)"
                                clearable
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>
                </v-row>

                <v-row justify="space-around" class="mb-3" no-gutters>
                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="brands"
                                v-model="brands"
                                id="brands"
                                label="Zīmols"
                                multiple
                                chips
                                :items="getItems(brandsEnum)"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="sizes"
                                v-model="sizes"
                                id="sizes"
                                label="Izmērs"
                                multiple
                                chips
                                :items="getAvailableSizes()"
                                :disabled="!isSizeSearchableCategorySelected"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="states"
                                v-model="states"
                                id="states"
                                label="Stāvoklis"
                                multiple
                                chips
                                :items="getItems(statesEnum)"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>
                </v-row>

                <v-row v-if="category === 'Nūjas'" justify="space-around" class="mb-3" no-gutters>
                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="stickSizes"
                                v-model="stickSizes"
                                id="stickSizes"
                                label="Nūjas izmērs"
                                multiple
                                chips
                                :items="sizesEnum.STICK.SIZE"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="bladeCurves"
                                v-model="bladeCurves"
                                id="bladeCurves"
                                label="Nūjas lāpstiņa"
                                multiple
                                chips
                                :items="sizesEnum.STICK.BLADE_CURVE"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-range-slider
                                name="stickFlex"
                                v-model="stickFlexRange"
                                id="stickFlex"
                                label="Nūjas flex"
                                :max="max"
                                :min="min"
                                step="1"
                                thumb-label="always"
                                class="align-center"
                            ></v-range-slider>
                        </v-flex>
                    </v-col>
                </v-row>

                <v-row v-if="category === 'Slidas'" justify="space-around" class="mb-3" no-gutters>
                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="skateLengths"
                                v-model="skateLengths"
                                id="skateLengths"
                                label="Slidu izmērs"
                                multiple
                                chips
                                :items="getSkateSizes()"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="3" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="skateWidths"
                                v-model="skateWidths"
                                id="skateWidths"
                                label="Slidu platums"
                                multiple
                                chips
                                :items="sizesEnum.SKATES.WIDTH"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>
                </v-row>

                <v-row justify="space-around" class="mb-3" no-gutters>
                    <v-col v-if="!isCriteriaEmpty" cols="12" md="12" class="text-center">
                        <v-btn
                            class="ma-1"
                            color="primary"
                            plain
                            v-on:click.prevent="clearSelectionCriteria"
                        >
                            {{ clearCiteriaTitle }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-col v-if="isPreparingList" cols="12" class="text-center">
                    <v-progress-circular
                        :size="40"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-col>

                <v-col v-else-if="!filteredAndSortedItems.length" cols="12" class="text-center">
                    <h3>Nav ierakstu</h3>
                </v-col>

                <v-row v-else no-gutters>
                    <v-col cols="12" md="3" v-for="(item, i) in visibleItems" :key="i">
                        <Card :item="item" :isForListing="true"/>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>

        <v-pagination
            v-if="!isListLoading && itemsList.length > 0"
            v-model="pagination.page"
            :length="Math.ceil(filteredAndSortedItems.length / pagination.perPage)"
            :total-visible="7"
        ></v-pagination>
    </v-container>
</template>

<script>
    import Card from '../components/Card';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'App',

        components: {
            Card,
        },

        data: () => ({
            newItemBtn: { title: '', path: '/newItem', icon: 'mdi-plus' },
            refreshDataBtn: { title: 'Atsvaidzināt', path: '', icon: 'mdi-cached' },
            clearCiteriaTitle: 'Notīrīt visus atlases kritērijus',
            isPreparingList: false,
            onlyActiveItems: [],
            filteredAndSortedItems: [],

            perPageList: [24, 48, 72],
            pagination: { page: 1, perPage: 24 },

            sortList: {
                'dateDesc': 'Jaunākās preces',
                'dateAsc': 'Vecākās preces',
                'priceDesc': 'Lielākās cenas',
                'priceAsc': 'Mazākās cenas',
            },

            sortBy: 'Jaunākās preces',
            brands: [],
            category: '',
            states: [],
            skateLengths: [],
            skateWidths: [],
            stickSizes: [],
            bladeCurves: [],
            stickFlexRange: [10, 140],
            min: 10,
            max: 140,
            sizes: [],
        }),

        computed: {
            ...mapGetters({
                isListLoading: 'items/isLoading',
                itemsList: 'items/itemsList',
                authenticated: 'auth/authenticated',
                isAuthLoading: 'auth/isLoading',
                categoriesEnum: 'enums/categories',
                statesEnum: 'enums/states',
                brandsEnum: 'enums/brands',
                sizesEnum: 'enums/sizes',
            }),
            getOnlyActiveItems() {
                if (!this.isAuthLoading) {
                    this.onlyActiveItems = this.itemsList.filter(item =>
                        this.authenticated || item.isActive
                    );
                }
                return this.onlyActiveItems;
            },
            getFilteredAndSortedItems() {
                this.getOnlyActiveItems;
                this.filter();
                return this.filteredAndSortedItems;
            },
            visibleItems() {
                return this.getFilteredAndSortedItems.slice(
                    (this.pagination.page - 1) * this.pagination.perPage,
                    this.pagination.page * this.pagination.perPage,
                );
            },
            isCriteriaEmpty() {
                return (this.brands.length || this.category || this.states.length || this.sizes.length)
                    ? false
                    : true;
            },
            isSizeSearchableCategorySelected() {
                return this.category === 'Ķiveres'
                    || this.category === 'Cimdi'
                    || this.category === 'Bikses'
                    || this.category === 'Plecu sargi'
                    || this.category === 'Kāju sargi'
                    || this.category === 'Elkoņu sargi'
                    || this.category === 'Somas'
                    || this.category === 'Komplekti';
            },
        },

        methods: {
            ...mapActions({
                getItemListAction: 'items/getItems',
                setMessageAction: 'message/setMessage',
            }),
            getItems(object) {
                return Object.values(object);
            },
            sort() {
                this.isPreparingList = true;
                if (this.sortBy === this.sortList.dateDesc) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.sort((b, a) =>
                        new Date(a.editedAt) - new Date(b.editedAt)
                    );
                } else if (this.sortBy === this.sortList.dateAsc) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.sort((a, b) =>
                        new Date(a.editedAt) - new Date(b.editedAt)
                    );
                } else if (this.sortBy === this.sortList.priceDesc) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.sort((a, b) =>
                        a.priceInMinorUnit < b.priceInMinorUnit
                    );
                } else if (this.sortBy === this.sortList.priceAsc) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.sort((a, b) =>
                        a.priceInMinorUnit > b.priceInMinorUnit
                    );
                }
                this.isPreparingList = false;
            },
            filter() {
                this.isPreparingList = true;
                this.filteredAndSortedItems = this.onlyActiveItems;

                if (this.brands.length > 0) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                        return this.brands.some(brand => this.brandsEnum[item.brand] === brand);
                    });
                }

                if (this.category) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                        return this.categoriesEnum[item.category] === this.category;
                    });
                }

                if (this.states.length > 0) {
                    this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                        return this.states.some(state => this.statesEnum[item.state] === state);
                    });
                }

                if (this.category === 'Slidas') {
                    if (this.skateLengths.length > 0) {
                        this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                            return this.skateLengths.some(size =>
                                (item.skateLength + ' - EUR ' + this.sizesEnum.SKATES.LENGTH[item.skateLength].EUR) === size
                            );
                        });
                    }

                    if (this.skateWidths.length > 0) {
                        this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                            return this.skateWidths.some(width => item.skateWidth === width);
                        });
                    }
                }

                if (this.category === 'Nūjas') {
                    if (this.stickSizes.length > 0) {
                        this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                            return this.stickSizes.some(size => item.stickSize === size);
                        });
                    }

                    if (this.bladeCurves.length > 0) {
                        this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                            return this.bladeCurves.some(curve => item.bladeCurve === curve);
                        });
                    }

                    if (this.stickFlexRange) {
                        this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                            return item.stickFlex >= this.stickFlexRange[0] && item.stickFlex <= this.stickFlexRange[1]
                        });
                    }
                }

                if (this.isSizeSearchableCategorySelected) {
                    if (this.sizes.length > 0) {
                        this.filteredAndSortedItems = this.filteredAndSortedItems.filter(item => {
                            if (this.category === 'Bikses'
                                || this.category === 'Plecu sargi'
                                || this.category === 'Elkoņu sargi'
                            ) {
                                return this.sizes.some(size => {
                                    var sizeKey = Object.keys(this.sizesEnum.PANTS_SHOULDER_ELBOW_PAD_SIZES)
                                        .find(key => this.sizesEnum.PANTS_SHOULDER_ELBOW_PAD_SIZES[key] === size);

                                    return item.size === sizeKey
                                });
                            } else {
                                return this.sizes.some(size => item.size === size);
                            }
                        });
                    }
                }

                this.sort();
                this.isPreparingList = false;
            },
            refreshData() {
                this.getItemListAction().then(updated => {
                    this.setMessageAction( { message: 'Dati atjaunoti!', isVisible: true, type: 'success' } );
                });
            },
            clearSelectionCriteria() {
                this.brands = [];
                this.category = '';
                this.states = [];
                this.skateLengths = [];
                this.skateWidths = [];
                this.stickSizes = [];
                this.bladeCurves = [];
                this.stickFlexRange = [10, 140];
                this.sizes = [];
                this.setMessageAction( { message: 'Atlases kritēriji notīrīti!', isVisible: true, type: 'success' } );
            },
            getSkateSizes() {
                const skateSizes = [ ];
                Object.entries(this.sizesEnum.SKATES.LENGTH).forEach(entry => {
                    const [key, value] = entry;
                    skateSizes.push( key + ' - EUR ' + value.EUR );
                });

                return skateSizes;
            },
            getAvailableSizes() {
                var availableSizes = [];
                if (this.category) {
                    if (this.category === 'Ķiveres') {
                        availableSizes = availableSizes.concat(this.sizesEnum.HELMET);
                    } else if (this.category === 'Cimdi') {
                        availableSizes = availableSizes.concat(this.sizesEnum.GLOVES);
                    } else if (this.category === 'Bikses'
                        || this.category === 'Plecu sargi'
                        || this.category === 'Elkoņu sargi'
                    ) {
                        availableSizes = availableSizes.concat(Object.values(this.sizesEnum.PANTS_SHOULDER_ELBOW_PAD_SIZES));
                    } else if (this.category === 'Kāju sargi') {
                        availableSizes = availableSizes.concat(this.sizesEnum.SHIN_GUARD);
                    } else if (this.category === 'Somas') {
                        availableSizes = availableSizes.concat(this.sizesEnum.BAG);
                    } else if (this.category === 'Komplekti') {
                        availableSizes = availableSizes.concat(this.sizesEnum.KIT);
                    }
                }

                return [...new Set(availableSizes)];
            }
        },
    }
</script>

<style>

</style>
