<template>
    <v-container fluid grid-list-md>
        <vue-instagram token="accessTokenHere" :count="5">
            <template slot="feeds" slot-scope="props">
            <li class="fancy-list"> {{ props.feed.link }} </li>
            </template>
            <template slot="error" slot-scope="props">
            <div class="fancy-alert"> {{ props.error.error_message }} </div>
            </template>
        </vue-instagram>

        <v-layout wrap class="mt-12">
            <v-col cols="12" offset-md="4" md="4">
                <v-flex class="text-center">
                    <h1>Piedāvājumi</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                <v-btn v-if="authenticated"
                    class="float-right mx-2"
                    fab
                    dark
                    color="success"
                    elevation="3"
                    small
                    :to="newOfferBtn.path"
                >
                    {{ newOfferBtn.title }}
                    <v-icon dark>
                        {{ newOfferBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>

            <v-flex v-if="isOffersLoading" xs12 class="text-center">
                <v-progress-circular
                    :size="40"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-flex>

            <v-flex v-else-if="!onlyActiveOfferList.length" xs12 class="text-center">
                <h3>Nav ierakstu</h3>
            </v-flex>

            <v-flex v-else xs12 md4 v-for="(offer, i) in onlyActiveOfferList" :key="i">
                <Card :item="offer" />
            </v-flex>
        </v-layout>

        <v-layout row wrap class="mt-12">
            <v-col cols="12" offset-md="4" md="4">
                <v-flex class="text-center">
                    <h1>Pakalpojumi</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                 <v-btn v-if="authenticated"
                    class="float-right mx-2"
                    fab
                    dark
                    color="success"
                    elevation="3"
                    small
                    :to="newServiceBtn.path"
                >
                    {{ newServiceBtn.title }}
                    <v-icon dark>
                        {{ newServiceBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>

            <v-flex v-if="isServicesLoading" xs12 class="text-center">
                <v-progress-circular
                    :size="40"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-flex>

            <v-flex v-else-if="!servicesList.length" xs12 class="text-center">
                <h3>Nav ierakstu</h3>
            </v-flex>

            <v-flex v-else>
                <v-flex v-if="onlyActiveServiceOfferList.length" xs12 offset-md2 md8>
                    <h3 class="mb-2">Piedāvājam</h3>
                    <List :services="onlyActiveServiceOfferList"/>
                </v-flex>

                <v-flex v-if="onlyActiveServiceBuyList.length" xs12 offset-md2 md8 class="mt-6">
                    <h3 class="mb-2">Iepērkam</h3>
                    <List :services="onlyActiveServiceBuyList"/>
                </v-flex>
            </v-flex>
        </v-layout>

        <v-layout wrap class="mt-12">
            <v-flex xs12 class="text-center pb-4">
                <h1>Kontakti</h1>
            </v-flex>
            <v-flex xs12 offset-sm2 sm8>
                <Stepper />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Card from '../components/Card';
    import List from '../components/List';
    import Stepper from '../components/Stepper';
    import VueInstagram from 'vue-instagram';

    export default {
        components: {
            Card,
            List,
            Stepper,
            VueInstagram,
        },

        data: () => ({
            newServiceBtn: { title: '', path: '/newService', icon: 'mdi-plus' },
            newOfferBtn: { title: '', path: '/newOffer', icon: 'mdi-plus' },
        }),

        computed: {
            ...mapGetters({
                authenticated: 'auth/authenticated',
                isServicesLoading: 'services/isLoading',
                servicesList: 'services/servicesList',
                isOffersLoading: 'offers/isLoading',
                offersList: 'offers/offersList',
            }),
            onlyActiveServiceList() {
                return this.servicesList.filter(service =>
                    this.authenticated || service.isActive
                )
            },
            onlyActiveServiceOfferList() {
                return this.onlyActiveServiceList.filter(service =>
                    service.serviceType === 'OFFER'
                );
            },
            onlyActiveServiceBuyList() {
                return this.onlyActiveServiceList.filter(service =>
                    service.serviceType === 'BUY'
                );
            },
            onlyActiveOfferList() {
                return this.offersList.filter(offer =>
                    this.authenticated || offer.isActive
                )
            },
        },
    }
</script>

<style scoped>

</style>
