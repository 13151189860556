<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-col cols="12" offset-md="3" md="6">
                <v-flex class="text-center">
                    <h1>Profils</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" offset-md="3" md="6" class="my-5">
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Lietotājvārds
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ user.username }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            E-pasts
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ user.email }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Parole
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            Pēdējo reizi maninīta:
                            <time-ago locale="en" :datetime="user.passEditedAt"></time-ago>
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4">
                        <p class="font-weight-regular">
                            Piekļuve
                        </p>
                    </v-col>
                    <v-col cols="8">
                        <p class="font-weight-bold">
                            {{ user.confirmed ? 'Atļauta' : 'Liegta' }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>

            <v-flex offset-md3 md6 xs12 >
                <v-divider></v-divider>
            </v-flex>

            <v-col cols="12" offset-md="3" md="6" class="my-5">
                <v-btn
                    dark
                    color="primary"
                    elevation="3"
                    small
                    :to="newPassBtn.path"
                >
                    {{ newPassBtn.title }}
                    <v-icon dark>
                        {{ newPassBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { TimeAgo } from 'vue2-timeago';

    export default {
        name: 'App',

        components: {
            TimeAgo,
        },

        data: () => ({
            newPassBtn: { title: 'Mainīt paroli', path: '/newPass', icon: '' },
        }),

        computed: {
            ...mapGetters({
                authenticated: 'auth/authenticated',
                user: 'auth/user',
            }),
        },
    }
</script>

<style scoped>

</style>
