<template>
    <v-app app>
        <Global v-if="isVisible && message.length > 0" :message="message" :isNotification="isNotification" />

        <div v-if="!$route.allowFullScreen" class="overflow-y-auto">>
            <Nav />
            <v-sheet app class="overflow-y-auto">
                <v-main>
                    <router-view></router-view>
                <Footer />
                </v-main>
            </v-sheet>
        </div>
        <div v-else>
            <router-view></router-view>
        </div>
        <v-overlay :value="isAuthLoading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Nav from './components/Nav';
    import Footer from './components/Footer';
    import Global from './components/popups/Global';

    export default {
        name: 'App',

        components: {
            Nav,
            Footer,
            Global,
        },

        data: () => ({

        }),

        computed: {
            ...mapGetters({
                message: 'message/getMessage',
                isVisible: 'message/getIsVisible',
                isNotification: 'message/getIsNotification',
                isAuthLoading: 'auth/isLoading',
            }),
        },
    }
</script>

<style>
    html, body, #app {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>
