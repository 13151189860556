<template>
    <v-stepper v-model="step" elevation="6">
        <Alert v-if="errors.visible" :error="errors.message" />

        <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">{{ stepOneTitle }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">{{ stepTwoTitle }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-form @submit.prevent="validateOption">
                    <h2>{{ stepOneTitle }}</h2>
                    <p>{{ stepOneDesc }}</p>

                    <v-radio-group
                        column
                        v-model="form.radioOption"
                        name="radioOption"
                        :error-messages="radioOptionErrors"
                        @input="$v.form.radioOption.$touch()"
                        @blur="$v.form.radioOption.$touch()"
                    >
                        <v-radio
                            v-for="(item, i) in getKeys(messageOptions)"
                            :key="i"
                            :label="messageOptions[item]"
                            :value="item"
                        ></v-radio>
                    </v-radio-group>
                    <v-btn
                        color="primary"
                        type="submit"
                        :loading="loading"
                        :disabled="loading"
                        small
                    >
                        {{ continueBtn.title }}
                        <v-icon right>
                            {{ continueBtn.icon }}
                        </v-icon>
                    </v-btn>
                </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-form @submit.prevent="submit">
                    <h2>{{ stepTwoTitle }}</h2>
                    <p>{{ stepTwoDesc }}</p>

                    <v-text-field
                        name="name"
                        v-model="form.name"
                        id="name"
                        label="Vārds"
                        type="text"
                        :error-messages="nameErrors"
                        required
                        @input="$v.form.name.$touch()"
                        @blur="$v.form.name.$touch()"
                    ></v-text-field>
                    <v-text-field
                        name="email"
                        v-model="form.email"
                        id="email"
                        label="Epasts"
                        type="email"
                        :error-messages="emailErrors"
                        required
                        @input="$v.form.email.$touch()"
                        @blur="$v.form.email.$touch()"
                    ></v-text-field>
                    <vue-tel-input-vuetify
                        name="phone"
                        id="phone"
                        label="Telefona numurs"
                        defaultCountry="LV"
                        :error-messages="phoneErrors"
                        @input="setPhoneData"
                        @blur="$v.form.phone.$touch()"
                    ></vue-tel-input-vuetify>
                    <v-textarea
                        name="description"
                        v-model="form.description"
                        id="description"
                        label="Apraksts"
                        type="text"
                        :error-messages="descriptionErrors"
                        required
                        @input="$v.form.description.$touch()"
                        @blur="$v.form.description.$touch()"
                    ></v-textarea>

                    <v-flex class="d-flex justify-space-between">
                        <v-btn
                            color="primary"
                            :loading="loading"
                            :disabled="loading"
                            v-on:click="step = 1"
                            small
                        >
                            <v-icon left>
                                {{ backBtn.icon }}
                            </v-icon>
                            <div class="hidden-sm-and-down">{{ backBtn.title }}</div>
                        </v-btn>
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="loading"
                            :disabled="loading"
                            small
                        >
                            <div class="hidden-sm-and-down">{{ sendBtn.title }}</div>
                            <v-icon right>
                                {{ sendBtn.icon }}
                            </v-icon>
                        </v-btn>
                    </v-flex>
                </v-form>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, maxLength, minLength, email } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';
    import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
	export default {
        mixins: [ validationMixin ],

        validations: {
            form: {
                name: { required, minLength: minLength(3), maxLength: maxLength(256) },
                email: { required, email, maxLength: maxLength(256) },
                phone: {
                    number: { required },
                    country: { required },
                    isValid: { isValidNumber(value) { return value; } },
                },
                description: { required, minLength: minLength(24), maxLength: maxLength(4096) },
                radioOption: { required },
            },
        },

        components: {
            Alert,
            VueTelInputVuetify,
        },

        data: () => ({
            stepOneTitle: 'Darbības izvēle',
            stepOneDesc: 'Izvēlieties veicamo darbību',
            stepTwoTitle: 'Formas aizpilde',
            stepTwoDesc: '',
            step: 1,
            continueBtn: { title: 'Turpināt', path: '', icon: 'mdi-chevron-right' },
            backBtn: { title: 'Atpakaļ', path: '', icon: 'mdi-chevron-left' },
            sendBtn: { title: 'Sūtīt', path: '', icon: 'mdi-send' },
            form: {
                name: '',
                email: '',
                phone: {
                    number: null,
                    country: null,
                    isValid: null,
                },
                description: '',
                radioOption: '',
                recaptcha: '',
            },
            errors: {
                name: null,
                email: null,
                phone: null,
                description: null,
                radioOption: null,
                visible: false,
            },
            loading: false,
        }),

        computed: {
            nameErrors () {
                const errors = [];
                if (this.errors.name) {
                    errors.push(this.errors.name[0]);
                    this.errors.name = null;
                }
                if (!this.$v.form.name.$dirty) return errors;
                !this.$v.form.name.maxLength && errors.push('Nedrīkst pārsniegt 256 rakstzīmes');
                !this.$v.form.name.minLength && errors.push('Jābūt vismaz 3 rakstzīmēm');
                !this.$v.form.name.required && errors.push('Obligāts lauks');
                return errors;
            },
            emailErrors () {
                const errors = [];
                if (this.errors.email) {
                    errors.push(this.errors.email[0]);
                    this.errors.email = null;
                }
                if (!this.$v.form.email.$dirty) return errors;
                !this.$v.form.email.email && errors.push('Nederīgs e-pasts');
                !this.$v.form.email.maxLength && errors.push('Nedrīkst pārsniegt 256 rakstzīmes');
                !this.$v.form.email.required && errors.push('Obligāts lauks');
                return errors;
            },
            phoneErrors () {
                const errors = [];
                if (this.errors.phone) {
                    errors.push(this.errors.phone[0]);
                    this.errors.phone = null;
                }
                if (!this.$v.form.phone.$dirty) return errors;
                !this.$v.form.phone.number.required && errors.push('Obligāts lauks');
                !this.$v.form.phone.country.required && errors.push('Obligāts lauks');
                !this.$v.form.phone.isValid.isValidNumber && errors.push('Nepareizs numurs');
                return errors;
            },
            descriptionErrors () {
                const errors = [];
                if (this.errors.description) {
                    errors.push(this.errors.description[0]);
                    this.errors.description = null;
                }
                if (!this.$v.form.description.$dirty) return errors;
                !this.$v.form.description.required && errors.push('Obligāts lauks');
                !this.$v.form.description.minLength && errors.push('Jābūt vismaz 24 rakstzīmēm garam');
                !this.$v.form.description.maxLength && errors.push('Nedrīkst pārsniegt 4096 rakstzīmes');
                return errors;
            },
            radioOptionErrors () {
                const errors = [];
                if (this.errors.radioOption) {
                    errors.push(this.errors.radioOption[0]);
                    this.errors.radioOption = null;
                }
                if (!this.$v.form.radioOption.$dirty) return errors;
                !this.$v.form.radioOption.required && errors.push('Obligāts lauks');
                return errors;
            },
            ...mapGetters({
                messageOptions: 'enums/messageOptions',
            }),
        },

		methods: {
            ...mapActions({
                setMessageAction: 'message/setMessage',
            }),

            validateOption() {
                this.$v.form.radioOption.$touch();
                if (this.$v.form.radioOption.$invalid) {
                    this.setMessageAction( { message: 'Dati satur kļūdas!', isVisible: true, type: 'error' } );
                } else {
                    this.stepTwoDesc = 'Izvēle: ' + this.messageOptions[this.form.radioOption];
				    this.step = 2;
                }
			},

			submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.setMessageAction( { message: 'Dati satur kļūdas!', isVisible: true, type: 'error' } );
                } else {
                    this.loading = true;
                    this.$recaptcha('sendMessage').then(token => {
                        this.form.recaptcha = token;
                        axios.post('/api/sendMessage', this.form).then(response => {
                            this.loading  = false;
                            this.form.recaptcha = '';
                            if (response.data.success) {
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                                this.step = 1;
                                this.form.name = '';
                                this.form.email = '';
                                this.form.phone.number = '';
                                this.form.phone.country = '';
                                this.form.phone.isValid = true;
                                this.form.description = '';
                            } else {
                                this.errors.message = response.data.message;
                                this.errors.visible = true;
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'error' } );

                                if (response.data.errors) {
                                    if (response.data.errors.name) {
                                        this.errors.name = response.data.errors.name;
                                    }
                                    if (response.data.errors.email) {
                                        this.errors.email = response.data.errors.email;
                                    }
                                    if (response.data.errors['phone.number']) {
                                        this.errors.phone = response.data.errors['phone.number'];
                                    }
                                    if (response.data.errors['phone.country']) {
                                        this.errors.phone = response.data.errors['phone.country'];
                                    }
                                    if (response.data.errors.description) {
                                        this.errors.description = response.data.errors.description;
                                    }
                                    if (response.data.errors.radioOption) {
                                        this.errors.radioOption = response.data.errors.radioOption;
                                    }
                                }
                            }
                            this.resetMessageTimeout(10000);
                        }).catch((error) => {
                            this.loading = true;
                            this.form.recaptcha = '';
                            this.setMessageAction( {
                                message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                isVisible: true,
                                type: 'error'
                            } );
                        });
                    });
                }
			},

            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },

            getKeys(object) {
                return Object.keys(object);
            },

            setPhoneData(number, phone) {
                this.form.phone.number = phone.number.e164;
                this.form.phone.country = phone.regionCode;
                this.form.phone.isValid = phone.isValid;
                this.$v.form.phone.$touch();
            },
		}
	}
</script>

<style>
</style>
