require('./bootstrap');
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://stickfixlatvia.unri.lv';

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import router from './router';

import Vuetify from 'vuetify';
Vue.use(Vuetify);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import Vuex from 'vuex';
Vue.use(Vuex);

import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, {
    siteKey: '6LdpZnsUAAAAAEj3J6PJAdBiXW2cQL8o7FTBuffp',
    loaderOptions: {
        autoHideBadge: true,
    }
});

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyByujrEpyGjhNipDpLFKnBTxMJWaWWiK2o',
        libraries: "places"
    }
});

import store from './store/index';

import App from './App';

const vuetifyOptions = {
    theme: { dark: true },
}

import './filters.js';

store.dispatch('items/getItemsList');
store.dispatch('services/getServicesList');
store.dispatch('offers/getOffersList');
store.dispatch('enums/getAllEnums');
store.dispatch('message/setData');
store.dispatch('messages/getMessagesList');
store.dispatch('auth/me').then(() => {;
    new Vue({
        el: '#app',
        router,
        vuetify: new Vuetify(vuetifyOptions),
        render: h => h(App),
        store,
    });
});
