<template>
    <v-footer
        color="grey darken-4 lighten-1"
        padless
        :elevation="4"
        class="mt-16"
    >
        <v-card
            flat
            tile
            class="grey darken-4 lighten-1 white--text text-center"
            width="100%"
        >
            <v-card-text>
                <v-btn
                    v-for="(item, i) in followUsList"
                    :key="i"
                    class="mx-4 white--text"
                    icon
                    :href="item.path"
                >
                    <v-icon size="24px">
                        {{ item.icon }}
                    </v-icon>
                </v-btn>
            </v-card-text>

            <v-container>
                <v-row>
                    <v-col
                        offset-sm="4" cols="8" offset-md="1" md="5"
                    >
                        <v-card
                            class="pa-2"
                            flat
                            tile
                            color="grey darken-4 lighten-1"
                        >
                            <v-list dense color="grey darken-4 lighten-1">
                                <v-list-item
                                    v-for="(item, i) in contactList"
                                    :key="i"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-left">{{ item.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>

                    <v-col
                        cols="12" md="6"
                    >
                        <v-card
                            class="pa-2"
                            flat
                            tile
                            grey darken-4 lighten-1
                        >
                            <GoogleMap />
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                &#169; 2016 - {{ new Date().getFullYear() }} : SIA <strong>StickFix Latvia</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
    import GoogleMap from '../components/GoogleMap';

    export default {
        components: {
            GoogleMap,
        },

        data: () => ({
            followUsList: [
                { path: 'https://www.facebook.com/stickfixlv', icon: 'mdi-facebook' },
                { path: 'https://www.instagram.com/stickfixlv', icon: 'mdi-instagram' },
            ],

            contactList: [
                { text: 'SIA StickFix', icon: 'mdi-map-marker-outline' },
                { text: 'Tallinas iela 28', icon: '' },
                { text: 'Centra rajons, Rīga', icon: '' },
                { text: 'LV-1001, Latvija', icon: '' },
                { text: 'Darba laiks: 10 - 18', icon: 'mdi-clock' },
                { text: '+371 20 000 000', icon: 'mdi-phone' },
                { text: 'test [at] email.com', icon: 'mdi-email' },
            ],
        }),
    }
</script>

<style scoped>

</style>
