export default {
    namespaced: true,

    state: {
        message: null,
        visible: null,
        type: null,
    },

    getters: {
        getMessage (state) {
            return state.message;
        },
        getIsVisible (state) {
            return state.visible;
        },
        getIsNotification (state) {
            return state.type === 'success';
        },
    },

    mutations: {
        SET_MESSAGE (state, value) {
            state.message = value
        },
        SET_VISIBLE (state, value) {
            state.visible = value
        },
        SET_TYPE (state, value) {
            state.type = value
        },
    },

    actions: {
        async setMessage ({ dispatch }, payload) {
            return dispatch('setData', payload)
        },

        setData ({ commit }, payload) {
            if (payload) {
                commit('SET_MESSAGE', payload.message);
                commit('SET_VISIBLE', payload.isVisible);
                commit('SET_TYPE', payload.type);

                let timeout = payload.type === 'error' ? 5000 : 3000;
                setTimeout(() => {
                    commit('SET_MESSAGE', null);
                    commit('SET_VISIBLE', false);
                    commit('SET_TYPE', null);
                }, timeout)
            } else {
                commit('SET_MESSAGE', null);
                commit('SET_VISIBLE', false);
                commit('SET_TYPE', null);
            }
        },
    }
}
