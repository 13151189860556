export default {
    namespaced: true,

    state: {
        categories: [],
        states: [],
        messageOptions: [],
        serviceTypes: [],
        brands: [],
        sizes: [],
    },

    getters: {
        categories (state) {
            return state.categories;
        },
        states (state) {
            return state.states;
        },
        messageOptions (state) {
            return state.messageOptions;
        },
        serviceTypes (state) {
            return state.serviceTypes;
        },
        brands (state) {
            return state.brands;
        },
        sizes (state) {
            return state.sizes;
        },
    },

    mutations: {
        SET_CATEGORIES (state, value) {
            state.categories = value
        },
        SET_STATES (state, value) {
            state.states = value
        },
        SET_MESSAGE_OPTIONS (state, value) {
            state.messageOptions = value
        },
        SET_SERVICE_TYPES (state, value) {
            state.serviceTypes = value
        },
        SET_BRANDS (state, value) {
            state.brands = value
        },
        SET_SIZES (state, value) {
            state.sizes = value
        },
    },

    actions: {
        getAllEnums({ commit }) {
            return axios.get('/api/getAllEnums').then(response => {
                commit('SET_CATEGORIES', response.data.CATEGORIES);
                commit('SET_STATES', response.data.STATES);
                commit('SET_MESSAGE_OPTIONS', response.data.MESSAGE_OPTIONS);
                commit('SET_SERVICE_TYPES', response.data.SERVICE_TYPES);
                commit('SET_BRANDS', response.data.BRANDS);
                commit('SET_SIZES', response.data.SIZES.original);
            }).catch(() => {
                commit('SET_CATEGORIES', []);
                commit('SET_STATES', []);
                commit('SET_MESSAGE_OPTIONS', []);
                commit('SET_SERVICE_TYPES', []);
                commit('SET_BRANDS', []);
                commit('SET_SIZES', []);
            });
        },

        getCategories({ commit }) {
            return axios.get('/api/getCategories').then(response => {
                commit('SET_CATEGORIES', response.data);
            }).catch(() => {
                commit('SET_CATEGORIES', []);
            });
        },

        getStates({ commit }) {
            return axios.get('/api/getStates').then(response => {
                commit('SET_STATES', response.data);
            }).catch(() => {
                commit('SET_STATES', []);
            });
        },

        getMessageOptions({ commit }) {
            return axios.get('/api/getMessageOptions').then(response => {
                commit('SET_MESSAGE_OPTIONS', response.data);
            }).catch(() => {
                commit('SET_MESSAGE_OPTIONS', []);
            });
        },

        getServiceTypes({ commit }) {
            return axios.get('/api/getServiceTypes').then(response => {
                commit('SET_SERVICE_TYPES', response.data);
            }).catch(() => {
                commit('SET_SERVICE_TYPES', []);
            });
        },

        getBrands({ commit }) {
            return axios.get('/api/getBrands').then(response => {
                commit('SET_BRANDS', response.data);
            }).catch(() => {
                commit('SET_BRANDS', []);
            });
        },
    }
}
