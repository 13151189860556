<template>
    <v-snackbar
        v-model="visible"
        :timeout="-1"
        v-bind:color="isNotification ? 'success' : 'error'"
        top
        right
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="visible = false"
            >
            Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        props: ['message', 'isNotification'],

        data: () => ({
            visible: true,
        }),
    }
</script>

<style scoped>

</style>
