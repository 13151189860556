export default {
    namespaced: true,

    state: {
        messageList: null,
        unreadMessageCount: null,
        isLoading: true,
    },

    getters: {
        messageList (state) {
            return state.messageList
        },
        unreadMessageCount (state) {
            return state.unreadMessageCount
        },
        isLoading (state) {
            return state.isLoading
        },
    },

    mutations: {
        SET_MESSAGE_LIST (state, value) {
            state.messageList = value
        },
        SET_UNREAD_MESSAGE_COUNT (state, value) {
            state.unreadMessageCount = value
        },
        SET_IS_LOADING (state, value) {
            state.isLoading = value
        },
    },

    actions: {
        async getMessages ({ dispatch }) {
            return dispatch('getMessagesList')
        },

        getMessagesList ({ commit }) {
            commit('SET_IS_LOADING', true);
            return axios.get('/api/checkAuth').then(response => {
                if (response.data.success) {
                    axios.get('/api/getMessages').then(response => {
                        if (response.data.success) {
                            commit('SET_MESSAGE_LIST', response.data.messages);
                            commit(
                                'SET_UNREAD_MESSAGE_COUNT',
                                response.data.messages.filter(message => message.isRead === 0).length
                            );
                            commit('SET_IS_LOADING', false);
                        } else {
                            commit('SET_MESSAGE_LIST', []);
                            commit('SET_UNREAD_MESSAGE_COUNT', 0);
                            commit('SET_IS_LOADING', true);
                        }
                    }).catch(() => {
                        commit('SET_MESSAGE_LIST', []);
                        commit('SET_UNREAD_MESSAGE_COUNT', 0);
                        commit('SET_IS_LOADING', true);
                    });
                } else {
                    commit('SET_MESSAGE_LIST', []);
                    commit('SET_UNREAD_MESSAGE_COUNT', 0);
                    commit('SET_IS_LOADING', true);
                }
            });
        }
    }
}
