<template>
    <v-container fluid grid-list-md>
        <v-layout wrap row class="mt-12">
            <v-col cols="12" offset-md="4" md="4">
                <v-flex class="text-center">
                    <h1>Statistika</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                <v-btn v-if="authenticated && getIsActionVisible(refreshStatisticsBtn)"
                    class="hidden-md-and-up float-left mx-2"
                    fab
                    dark
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshStatisticsData"
                    :loading="isItemsLoading" :disabled="isItemsLoading"
                >
                    <v-icon >
                        {{ refreshStatisticsBtn.icon }}
                    </v-icon>
                </v-btn>

                <v-btn v-if="authenticated && getIsActionVisible(refreshStatisticsBtn)"
                    class="hidden-sm-and-down float-left mx-2"
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshStatisticsData"
                    :loading="isItemsLoading" :disabled="isItemsLoading"
                >
                    {{ refreshStatisticsBtn.title }}
                    <v-icon right>
                        {{ refreshStatisticsBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col v-if="isItemsLoading" cols="12" md="12">
                <v-flex class="text-center">
                    <v-progress-circular
                        :size="40"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-flex>
            </v-col>

            <v-col v-else-if="!itemsList.length" cols="12" md="12">
                <v-flex class="text-center">
                    <h3>Nav ierakstu</h3>
                </v-flex>
            </v-col>

            <v-col v-else cols="12" md="12">
                <v-row justify="space-around" class="mb-3" no-gutters>
                    <v-col cols="12" md="4" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="column"
                                v-model="column"
                                id="column"
                                label="Kolonna"
                                :items="getAvailableItems(true, false)"
                                item-text="name"
                                clearable
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>

                    <v-col cols="12" md="1" class="pa-2">
                        <v-btn
                            class="ma-1"
                            color="primary"
                            plain
                            v-on:click.prevent="switchColumnAndRow()"
                        >
                            <v-icon dark>mdi-swap-horizontal</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-2">
                       <v-flex>
                            <v-autocomplete
                                name="row"
                                v-model="row"
                                id="row"
                                label="Rinda"
                                :items="getAvailableItems(false, true)"
                                item-text="name"
                                clearable
                            ></v-autocomplete>
                        </v-flex>
                    </v-col>
                </v-row>

                <v-row justify="space-around" class="mb-3" no-gutters>
                    <v-col v-if="!isTableHeadersEmpty" cols="12" md="12" class="text-center">
                        <v-btn
                            class="ma-1"
                            color="primary"
                            plain
                            v-on:click.prevent="clearHeaders"
                        >
                            {{ clearHeadersDescription }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="showTable" no-gutters>
                    <h4>{{ tableTitle }}</h4>
                    <v-col cols="12" md="12">
                        <Table
                            :headers="getTableHeaders"
                            :items="getTableData"
                            :loading="isTableLoading"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap class="mt-12">
            <v-col cols="12" offset-md="4" md="4">
                <v-flex class="text-center">
                    <h1>Lietotāji</h1>
                </v-flex>
            </v-col>

            <v-col cols="12" md="4">
                <v-btn v-if="authenticated && getIsActionVisible(newUserBtn)"
                    class="hidden-md-and-up float-right mx-2"
                    fab
                    dark
                    color="success"
                    elevation="3"
                    small
                    :loading="isUsersLoading" :disabled="isUsersLoading"
                    :to="newUserBtn.path"
                >
                    <v-icon >
                        {{ newUserBtn.icon }}
                    </v-icon>
                </v-btn>
                <v-btn v-if="authenticated && getIsActionVisible(newUserBtn)"
                    class="hidden-sm-and-down float-right mx-2"
                    color="success"
                    elevation="3"
                    small
                    :loading="isUsersLoading" :disabled="isUsersLoading"
                    :to="newUserBtn.path"
                >
                    {{ newUserBtn.title }}
                    <v-icon right>
                        {{ newUserBtn.icon }}
                    </v-icon>
                </v-btn>

                <v-btn v-if="authenticated && getIsActionVisible(refreshUsersBtn)"
                    class="hidden-md-and-up float-right mx-2"
                    fab
                    dark
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshUsersData"
                    :loading="isUsersLoading" :disabled="isUsersLoading"
                >
                    <v-icon >
                        {{ refreshUsersBtn.icon }}
                    </v-icon>
                </v-btn>
                <v-btn v-if="authenticated && getIsActionVisible(refreshUsersBtn)"
                    class="hidden-sm-and-down float-right mx-2"
                    color="info"
                    elevation="3"
                    small
                    v-on:click.prevent="refreshUsersData"
                    :loading="isUsersLoading" :disabled="isUsersLoading"
                >
                    {{ refreshUsersBtn.title }}
                    <v-icon right>
                        {{ refreshUsersBtn.icon }}
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col v-if="isUsersLoading" cols="12" md="12">
                <v-flex class="text-center">
                    <v-progress-circular
                        :size="40"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-flex>
            </v-col>

            <v-col v-else-if="!userList.length" cols="12" md="12">
                <v-flex class="text-center">
                    <h3>Nav ierakstu</h3>
                </v-flex>
            </v-col>

            <v-col v-else cols="12" offset-md="2" md="8">
                <List :users="userList"/>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import List from '../components/List';
    import Table from '../components/Table';

    export default {
        name: 'App',

        components: {
            List,
            Table
        },

        data: () => ({
            refreshStatisticsBtn: { title: 'Atsvaidzināt', path: '', icon: 'mdi-cached', role: 'seeStatistics' },

            newUserBtn: { title: 'Jauns lietotājs', path: '/register', icon: 'mdi-plus', role: 'addNewUser' },
            refreshUsersBtn: { title: 'Atsvaidzināt', path: '', icon: 'mdi-cached', role: 'addNewUser' },

            column: null,
            row: null,

            isTableLoading: true,
            tableTitle: '',
            tableItems: [
                { name: 'Visas kategorijas', nameValue: 'category', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state' ]
                },
                { name: 'Slidas', nameValue: 'skates', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Nūjas', nameValue: 'sticks', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Ķiveres', nameValue: 'helmets', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Cimdi', nameValue: 'gloves', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Bikses', nameValue: 'pants', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Plecu sargi', nameValue: 'shoulderPads', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Kāju sargi', nameValue: 'shinGuards', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Elkoņu sargi', nameValue: 'elbowPads', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Somas', nameValue: 'bags', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Komplekti', nameValue: 'kits', disabled: false,
                    allowedToCompareWith: [ 'brand', 'status', 'state', 'size' ]
                },
                { name: 'Zīmols', nameValue: 'brand', disabled: false,
                    allowedToCompareWith: [ 'category', 'skates', 'sticks', 'helmets', 'gloves', 'pants', 'shoulderPads', 'shinGuards', 'elbowPads', 'bags', 'kits', 'status', 'state' ]
                },
                { name: 'Stāvoklis', nameValue: 'status', disabled: false,
                    allowedToCompareWith: [ 'category', 'skates', 'sticks', 'helmets', 'gloves', 'pants', 'shoulderPads', 'shinGuards', 'elbowPads', 'bags', 'kits', 'brand', 'state' ]
                },
                { name: 'Statuss', nameValue: 'state', disabled: false,
                    allowedToCompareWith: [ 'category', 'skates', 'sticks', 'helmets', 'gloves', 'pants', 'shoulderPads', 'shinGuards', 'elbowPads', 'bags', 'kits', 'brand', 'status' ]
                },
                { name: 'Izmērs', nameValue: 'size', disabled: false,
                    allowedToCompareWith: [ 'skates', 'sticks', 'helmets', 'gloves', 'pants', 'shoulderPads', 'shinGuards', 'elbowPads', 'bags', 'kits' ]
                },
            ],

            statuses: [ { value: 1, name: 'Aktīvs' }, { value: 0, name: 'Neaktīvs' }, ],

            clearHeadersDescription: 'Notīrīt datus',
        }),

        computed: {
            ...mapGetters({
                authenticated: 'auth/authenticated',
                roles: 'auth/roles',
                userList: 'users/userList',
                isUsersLoading: 'users/isLoading',
                isItemsLoading: 'items/isLoading',
                itemsList: 'items/itemsList',
                categories: 'enums/categories',
                states: 'enums/states',
                brands: 'enums/brands',
                sizesEnum: 'enums/sizes',
            }),
            isSuperAdmin() {
                return this.roles.some(record => record.role === 'superAdmin');
            },
            showTable() {
                if (this.column && this.row) {
                    this.tableTitle = 'Skaits: ' + this.column + ' / ' + this.row;
                    return true
                }
                return false;
            },
            getTableHeaders() {
                let tableHeaders = [];
                let rowData = this.tableItems.filter(item => item.name === this.row);
                let useTotal = true;

                tableHeaders.push( { text: this.row, align: 'start', sortable: false, value: rowData[0].nameValue } );
                if (this.column === 'Zīmols') {
                    Object.keys(this.brands).forEach((brandKey) => {
                        tableHeaders.push( { text: this.brands[brandKey], value: brandKey } );
                    });
                } else if (this.column === 'Visas kategorijas') {
                    Object.keys(this.categories).forEach((categoryKey) => {
                        tableHeaders.push( { text: this.categories[categoryKey], value: categoryKey } );
                    });
                } else if (this.column === 'Stāvoklis') {
                    Object.keys(this.states).forEach((stateKey) => {
                        tableHeaders.push( { text: this.states[stateKey], value: stateKey } );
                    });
                } else if (this.column === 'Statuss') {
                    this.statuses.forEach((status) => {
                        tableHeaders.push( { text: status.name, value: String(status.value) } );
                    });
                } else if (this.column === 'Izmērs') {
                    tableHeaders = tableHeaders.concat(this.getAvailableSizes(this.row));
                    tableHeaders.push( { text: 'Cits', value: 'other' } );
                } else {
                    let columnData = this.tableItems.filter(item => item.name === this.column);
                    tableHeaders.push( { text: columnData[0].name, value: columnData[0].nameValue } );
                    useTotal = false;
                }

                if (useTotal) {
                    tableHeaders.push( { text: 'Kopā', value: 'total' } );
                }

                return tableHeaders;
            },
            getTableData() {
                let tableItems = [];

                if (this.row === 'Zīmols') {
                    Object.keys(this.brands).forEach((brandKey) => {
                        var row = {};
                        let items = this.itemsList;
                        items = items.filter(item => item.brand === brandKey);
                        row = this.getRowData(row, items);
                        row.brand = this.brands[brandKey];
                        row.total = items.length ? items.length : '-';
                        tableItems.push( row );
                    });

                    var row = {};
                    row = this.getRowData(row, this.itemsList);
                    row.brand = 'Kopā';
                    row.total = this.itemsList.length;
                    tableItems.push( row );
                } else if (this.row === 'Visas kategorijas') {
                    Object.keys(this.categories).forEach((categoryKey) => {
                        var row = {};
                        let items = this.itemsList;
                        items = items.filter(item => item.category === categoryKey);
                        row = this.getRowData(row, items);
                        row.category = this.categories[categoryKey];
                        row.total = items.length ? items.length : '-';
                        tableItems.push( row );
                    });

                    var row = {};
                    row = this.getRowData(row, this.itemsList);
                    row.category = 'Kopā';
                    row.total = this.itemsList.length;
                    tableItems.push( row );
                } else if (this.row === 'Stāvoklis') {
                    Object.keys(this.states).forEach((stateKey) => {
                        var row = {};
                        let items = this.itemsList;
                        items = items.filter(item => item.state === stateKey);
                        row = this.getRowData(row, items);
                        row.status = this.states[stateKey];
                        row.total = items.length ? items.length : '-';
                        tableItems.push( row );
                    });

                    var row = {};
                    row = this.getRowData(row, this.itemsList);
                    row.status = 'Kopā';
                    row.total = this.itemsList.length;
                    tableItems.push( row );
                } else if (this.row === 'Statuss') {
                    this.statuses.forEach((status) => {
                        var row = {};
                        let items = this.itemsList;
                        items = items.filter(item => item.isActive === status.value);
                        row = this.getRowData(row, items);
                        row.state = status.name;
                        row.total = items.length ? items.length : '-';
                        tableItems.push( row );
                    });

                    var row = {};
                    row = this.getRowData(row, this.itemsList);
                    row.state = 'Kopā';
                    row.total = this.itemsList.length;
                    tableItems.push( row );
                } else if (this.row === 'Izmērs') {
                    let items = this.itemsList;
                    let rowData = this.tableItems.filter(item => item.name === this.row);
                    let columnData = this.tableItems.filter(item => item.name === this.column);
                    let categoryKey = this.getCategoryKey(this.column);
                    items = items.filter(item => item.category === categoryKey);

                    let itemSizeCount = 0;
                    this.getAvailableSizes(this.column).forEach((size) => {
                        var foundItems = [];
                        if (categoryKey === 'SKATES') {
                            foundItems = items.filter(item => item.skateLength === size.value);
                        } else if (categoryKey === 'STICKS') {
                            foundItems = items.filter(item => item.stickSize === size.value);
                        } else {
                            foundItems = items.filter(item => item.size === size.value);
                        }
                        itemSizeCount += foundItems.length;

                        var row = {};
                        row[rowData[0].nameValue] = size.text;
                        row[columnData[0].nameValue] = foundItems.length ? foundItems.length : '-';
                        tableItems.push( row );
                    });

                    var row = {};
                    row[rowData[0].nameValue] = 'Cits';
                    row[columnData[0].nameValue] = (items.length - itemSizeCount) ? (items.length - itemSizeCount) : '-';
                    tableItems.push( row );

                    var row = {};
                    row[rowData[0].nameValue] = 'Kopā';
                    row[columnData[0].nameValue] = items.length  ? items.length  : '-';
                    tableItems.push( row );
                } else {
                    let rowData = this.tableItems.filter(item => item.name === this.row);
                    let categoryKey = this.getCategoryKey(this.row);
                    let items = this.itemsList.filter(item => item.category === categoryKey);

                    var row = {};
                    row = this.getRowData(row, items);
                    row[rowData[0].nameValue] = 'Skaits';
                    row.total = items.length ? items.length : '-';
                    tableItems.push( row );
                }

                this.isTableLoading = false;
                return tableItems;
            },
            isTableHeadersEmpty() {
                return (this.column || this.row) ? false : true;
            },
        },

        methods: {
            ...mapActions({
                getItemListAction: 'items/getItems',
                getUsersListAction: 'users/getUsers',
                setMessageAction: 'message/setMessage',
            }),
            refreshUsersData() {
                this.getUsersListAction().then(refreshed => {
                    this.setMessageAction( { message: 'Dati atjaunoti!', isVisible: true, type: 'success' } );
                });
            },
            refreshStatisticsData() {
                this.getItemListAction().then(refreshed => {
                    this.setMessageAction( { message: 'Dati atjaunoti!', isVisible: true, type: 'success' } );
                });
            },
            getIsActionVisible(action) {
                if (this.roles) {
                    return this.isSuperAdmin || !action.role || this.userHasRole(action.role);
                } else {
                    return false;
                }
            },
            userHasRole(role) {
                return this.roles.some(record => record.role === role);
            },
            switchColumnAndRow() {
                if (this.column && this.row) {
                    [this.column, this.row] = [this.row, this.column];
                }
            },
            getRowData(row, items) {
                if (this.column === 'Zīmols') {
                    Object.keys(this.brands).forEach((brandKey) => {
                        let count = items.filter(item => item.brand === brandKey).length;
                        row[brandKey] = count ? count : '-';
                    });
                } else if (this.column === 'Visas kategorijas') {
                    Object.keys(this.categories).forEach((categoryKey) => {
                        let count = items.filter(item => item.category === categoryKey).length;
                        row[categoryKey] = count ? count : '-';
                    });
                } else if (this.column === 'Stāvoklis') {
                    Object.keys(this.states).forEach((stateKey) => {
                        let count = items.filter(item => item.state === stateKey).length;
                        row[stateKey] = count ? count : '-';
                    });
                } else if (this.column === 'Statuss') {
                    this.statuses.forEach((status) => {
                        let count = items.filter(item => item.isActive === status.value).length;
                        row[status.value] = count ? count : '-';
                    });
                } else if (this.column === 'Izmērs') {
                    let categoryKey = this.getCategoryKey(this.row);
                    let count = 0;
                    let itemSizeCount = 0;

                    this.getAvailableSizes(this.row).forEach((size) => {
                        if (categoryKey === 'SKATES') {
                            count = items.filter(item => item.skateLength === size.value).length;
                        } else if (categoryKey === 'STICKS') {
                            count = items.filter(item => item.stickSize === size.value).length;
                        } else {
                            count = items.filter(item => item.size === size.value).length;
                        }
                        row[size.value] = count ? count : '-';
                        itemSizeCount += count;
                    });
                    row.other = (items.length - itemSizeCount) ? (items.length - itemSizeCount) : '-';
                } else {
                    let categoryKey = this.getCategoryKey(this.column);
                    let count = items.filter(item => item.category === categoryKey).length;
                    let columnData = this.tableItems.filter(item => item.name === this.column);
                    row[columnData[0].nameValue] = count ? count : '-';
                }
                return row;
            },
            clearHeaders() {
                this.column = null;
                this.row = null;
                this.setMessageAction( { message: 'Dati notīrīti!', isVisible: true, type: 'success' } );
            },
            getAvailableSizes(category) {
                var availableSizes = [];
                if (category) {
                    if (category === 'Ķiveres') {
                        this.sizesEnum.HELMET.forEach(size => {
                            availableSizes.push( { text: size, value: String(size) } );
                        });
                    } else if (category === 'Cimdi') {
                        this.sizesEnum.GLOVES.forEach(size => {
                            availableSizes.push( { text: size, value: String(size) } );
                        });
                    } else if (category === 'Bikses'
                        || category === 'Plecu sargi'
                        || category === 'Elkoņu sargi'
                    ) {
                        Object.entries(this.sizesEnum.PANTS_SHOULDER_ELBOW_PAD_SIZES).forEach(entry => {
                            const [key, value] = entry;
                            availableSizes.push( { text: value, value: key } );
                        });
                    } else if (category === 'Kāju sargi') {
                        this.sizesEnum.SHIN_GUARD.forEach(size => {
                            availableSizes.push( { text: size, value: String(size) } );
                        });
                    } else if (category === 'Somas') {
                        this.sizesEnum.BAG.forEach(size => {
                            availableSizes.push( { text: size, value: String(size) } );
                        });
                    } else if (category === 'Komplekti') {
                        this.sizesEnum.KIT.forEach(size => {
                            availableSizes.push( { text: size, value: String(size) } );
                        });
                    } else if (category === 'Slidas') {
                        Object.entries(this.sizesEnum.SKATES.LENGTH).forEach(entry => {
                            const [key, value] = entry;
                            availableSizes.push( { text: key + ' (EUR ' + value.EUR + ')', value: key } );
                        });
                    } else if (category === 'Nūjas') {
                        this.sizesEnum.STICK.SIZE.forEach(size => {
                            availableSizes.push( { text: size, value: String(size) } );
                        });
                    }
                }

                return [...new Set(availableSizes)];
            },
            getCategoryKey(categoryText) {
                let categoryKey = null;
                Object.entries(this.categories).forEach((category) => {
                    const [key, value] = category;
                    if (value === categoryText) {
                        categoryKey = key;
                    }
                });
                return categoryKey;
            },
            getAvailableItems(isColumn, isRow) {
                let availableItems = this.tableItems;
                let takenItem = null;

                if (isColumn && this.row) {
                    availableItems.forEach((item, index) => {
                        if (item.name === this.row) {
                            takenItem = item;
                        }
                        availableItems[index].disabled = false;
                    });
                } else if (isRow && this.column) {
                    availableItems.forEach((item, index) => {
                        if (item.name === this.column) {
                            takenItem = item;
                        }
                        availableItems[index].disabled = false;
                    });
                } else {
                    availableItems.forEach((item, index) => {
                        availableItems[index].disabled = false;
                    });
                }

                if (takenItem !== null) {
                    availableItems.forEach((item, index) => {
                        if (item.name === this.column || item.name === this.row) {
                            availableItems[index].disabled = true;
                        } else if (!takenItem.allowedToCompareWith.includes(item.nameValue)) {
                            availableItems[index].disabled = true;
                        } else {
                            availableItems[index].disabled = false;
                        }
                    });
                }

                return availableItems;
            },
        },

        created: function() {
            this.getUsersListAction();
        },
    }
</script>

<style scoped>

</style>
