<template>
    <v-container fluid class="py-16">
        <v-layout row wrap>
            <v-flex xs12 sm6 offset-sm3 class="text-right">
                <v-btn
                    class="ml-2"
                    v-bind:color="form.isActive ? 'error' : 'primary'"
                    dark
                    small
                    v-on:click.prevent="markService(form.id)"
                    :loading="loading" :disabled="loading"
                >
                    {{ form.isActive ? 'Marķēt neaktīvu' : 'Marķēt aktīvu' }}
                    <v-icon
                        dark
                        right
                    >
                        {{ form.isActive ? 'mdi-eye-off' : 'mdi-eye' }}
                    </v-icon>
                </v-btn>

                <v-btn
                    class="ml-2"
                    color="error"
                    dark
                    small
                    v-on:click.prevent="deleteService(form.id)"
                    :loading="loading" :disabled="loading"
                >
                    Dzēst
                    <v-icon
                        dark
                        right
                    >
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-flex>

            <v-flex xs12 sm6 offset-sm3 class="text-center" mt-5>
                <Alert v-if="errors.visible" :error="errors.message" />
                <h1>Labot pakalopjumu</h1>
            </v-flex>

            <v-flex xs12 sm6 offset-sm3 mt-3>
                <form @submit.prevent="editService">
                    <v-layout column>
                        <v-flex>
                            <v-autocomplete
                                name="serviceType"
                                v-model="form.serviceType"
                                id="serviceType"
                                label="Veids"
                                :items="getItems(serviceTypes)"
                                placeholder="Izvēlies veidu..."
                                :error-messages="serviceTypeErrors"
                                required
                                @input="$v.form.serviceType.$touch()"
                                @blur="$v.form.serviceType.$touch()"
                            ></v-autocomplete>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                name="title"
                                v-model="form.title"
                                id="title"
                                label="Nosaukums"
                                type="text"
                                :error-messages="titleErrors"
                                :counter="256"
                                required
                                @input="$v.form.title.$touch()"
                                @blur="$v.form.title.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                name="note"
                                v-model="form.note"
                                id="note"
                                label="Piezīme"
                                type="text"
                                :error-messages="noteErrors"
                                :counter="256"
                                @input="$v.form.note.$touch()"
                                @blur="$v.form.note.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                name="price"
                                v-model="form.price"
                                id="price"
                                label="Cena"
                                prefix="€"
                                :error-messages="priceErrors"
                                @input="$v.form.price.$touch()"
                                @blur="$v.form.price.$touch()"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-checkbox
                                name="isActive"
                                v-model="form.isActive"
                                id="isActive"
                                color="primary"
                                :error-messages="isActiveErrors"
                                @input="$v.form.isActive.$touch()"
                                @blur="$v.form.isActive.$touch()"
                            >
                                <template v-slot:label>Pakalpojums ir aktīvs?</template>
                            </v-checkbox>
                        </v-flex>

                        <v-flex mt-5>
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                small
                            >
                                Labot
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validationMixin } from 'vuelidate';
    import { required, maxLength, minLength, decimal, minValue, maxValue } from 'vuelidate/lib/validators';
    import Alert from '../components/popups/Alert';

    export default {
        mixins: [ validationMixin ],

        validations: {
            form: {
                serviceType: { required },
                title: { required, minLength: minLength(3), maxLength: maxLength(256) },
                note: { minLength: minLength(3), maxLength: maxLength(256) },
                price: { decimal, minValue: minValue(0.00), maxValue: maxValue(2000000000.00) },
                isActive: { },
            },
        },

        components: {
            Alert,
        },

        data: () => ({
            form: {
                id: null,
                serviceType: null,
                title: null,
                note: null,
                price: null,
                isActive: null,
                recaptcha: '',
            },
            errors: {
                serviceType: null,
                title: null,
                note: null,
                price: null,
                isActive: null,
                message: null,
                visible: false,
            },
            loading: false,
        }),

        computed: {
            serviceTypeErrors() {
                const errors = [];
                if (this.errors.serviceType) {
                    errors.push(this.errors.serviceType[0]);
                    this.errors.serviceType = null;
                }
                if (!this.$v.form.serviceType.$dirty) return errors;
                !this.$v.form.serviceType.required && errors.push('Obligāts lauks');
                return errors;
            },
            titleErrors() {
                const errors = [];
                if (this.errors.title) {
                    errors.push(this.errors.title[0]);
                    this.errors.title = null;
                }
                if (!this.$v.form.title.$dirty) return errors;
                !this.$v.form.title.required && errors.push('Obligāts lauks');
                !this.$v.form.title.maxLength && errors.push('Nedrīkst pārsniegt 256 rakstzīmes');
                !this.$v.form.title.minLength && errors.push('Jābūt vismaz 3 rakstzīmēm');
                return errors
            },
            noteErrors() {
                const errors = [];
                if (this.errors.note) {
                    errors.push(this.errors.note[0]);
                    this.errors.note = null;
                }
                if (!this.$v.form.note.$dirty) return errors;
                !this.$v.form.note.maxLength && errors.push('Nedrīkst pārsniegt 256 rakstzīmes');
                !this.$v.form.note.minLength && errors.push('Jābūt vismaz 3 rakstzīmēm');
                return errors;
            },
            priceErrors() {
                const errors = [];
                if (this.errors.price) {
                    errors.push(this.errors.price[0]);
                    this.errors.price = null;
                }
                if (!this.$v.form.price.$dirty) return errors;
                !this.$v.form.price.decimal && errors.push('Drīkst saturēt tikai naturālus skaitļus vai skaitļus ar punktu');
                !this.$v.form.price.minValue && errors.push('Jābūt pozitīvai vērtībai');
                !this.$v.form.price.maxValue && errors.push('Pārāk liela vērtība');
                return errors;
            },
            isActiveErrors() {
                const errors = [];
                if (this.errors.isActive) {
                    errors.push(this.errors.isActive[0]);
                    this.errors.isActive = null;
                }
                if (!this.$v.form.isActive.$dirty) return errors;
                return errors;
            },
            ...mapGetters({
                serviceTypes: 'enums/serviceTypes',
            }),
        },

        methods: {
            ...mapActions({
                getServiceListAction: 'services/getServices',
                setMessageAction: 'message/setMessage',
            }),
            editService() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.errors.message = 'Dati satur kļūdas.';
                    this.errors.visible = true;
                    this.resetMessageTimeout(10000);
                    this.setMessageAction( { message: 'Kļūda!', isVisible: true, type: 'error' } );
                } else {
                    this.loading = true;
                    this.$recaptcha('editService').then(token => {
                        this.form.recaptcha = token;
                        axios.post('/api/editService', this.form).then(response => {
                            this.loading  = false;
                            this.form.recaptcha = '';
                            if (response.data.success) {
                                this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                                this.getServiceListAction();
                                this.$router.push({ name: 'Home' });
                            } else {
                                this.errors.message = response.data.message;
                                this.errors.visible = true;
                                this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );

                                if (response.data.errors) {
                                    if (response.data.errors.serviceType) {
                                        this.errors.serviceType = response.data.errors.serviceType;
                                    }
                                    if (response.data.errors.title) {
                                        this.errors.title = response.data.errors.title;
                                    }
                                    if (response.data.errors.note) {
                                        this.errors.note = response.data.errors.note;
                                    }
                                    if (response.data.errors.price) {
                                        this.errors.price = response.data.errors.price;
                                    }
                                    if (response.data.errors.isActive) {
                                        this.errors.isActive = response.data.errors.isActive;
                                    }
                                }
                            }
                            this.resetMessageTimeout(10000);
                        }).catch((error) => {
                            this.loading = true;
                            this.form.recaptcha = '';
                            this.setMessageAction( {
                                message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                                isVisible: true,
                                type: 'error'
                            } );
                        });
                    });
                }
            },
            markService(id) {
                this.loading = true;
                axios.get('/api/markService/' + id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                        this.getServiceListAction();
                        this.$router.push({ name: 'Home' });
                    } else {
                        this.errors.message = response.data.message;
                        this.errors.visible = true;
                        this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                    }
                    this.resetMessageTimeout(10000);
                }).catch((error) => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
            },
            deleteService(id) {
                this.loading = true;
                axios.get('/api/deleteService/' + id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.setMessageAction( { message: response.data.message, isVisible: true, type: 'success' } );
                        this.getServiceListAction();
                        this.$router.push({ name: 'Home' });
                    } else {
                        this.errors.message = response.data.message;
                        this.errors.visible = true;
                        this.setMessageAction( { message: this.errors.message, isVisible: true, type: 'error' } );
                    }
                    this.resetMessageTimeout(10000);
                }).catch((error) => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                });
            },
            resetMessageTimeout(time) {
                setTimeout(() => {
                    this.errors.visible = false;
                }, time)
            },
            getItems(object) {
                return Object.values(object);
            },
        },

        created: function() {
            if (!this.$route.params.id
                || !Number.isInteger(parseInt(this.$route.params.id))
                || parseInt(this.$route.params.id) <= 0
            ) {
                this.setMessageAction( { message: 'Ieraksts nav atrasts!', isVisible: true, type: 'error' } );
                this.$router.push({ name: 'Home' });
            } else {
                this.loading = true;
                axios.get('/api/getService/' + this.$route.params.id).then(response => {
                    this.loading  = false;
                    if (response.data.success) {
                        this.form.id = response.data.service.id;
                        this.form.serviceType = this.serviceTypes[response.data.service.serviceType];
                        this.form.title = response.data.service.title;
                        this.form.note = response.data.service.note;
                        this.form.price = response.data.service.priceInMinorUnit / 100;
                        this.form.isActive = response.data.service.isActive;
                    } else {
                        this.setMessageAction( { message: 'Ieraksts nav atrasts!', isVisible: true, type: 'error' } );
                        this.$router.push({ name: 'Home' });
                    }
                }).catch(error => {
                    this.loading = true;
                    this.setMessageAction( {
                        message: 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!',
                        isVisible: true,
                        type: 'error'
                    } );
                    this.$router.push({ name: 'Home' });
                });
            }
        },
    }
</script>

<style scoped>

</style>
