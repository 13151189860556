export default {
    namespaced: true,

    state: {
        userList: null,
        isLoading: true,
    },

    getters: {
        userList (state) {
            return state.userList
        },
        isLoading (state) {
            return state.isLoading
        },
    },

    mutations: {
        SET_USER_LIST (state, value) {
            state.userList = value
        },
        SET_IS_LOADING (state, value) {
            state.isLoading = value
        },
    },

    actions: {
        async getUsers ({ dispatch }) {
            return dispatch('getUsersList')
        },

        getUsersList ({ commit }) {
            commit('SET_IS_LOADING', true);
            return axios.get('/api/getUsers').then(response => {
                if (response.data.success) {
                    commit('SET_USER_LIST', response.data.users);
                    commit('SET_IS_LOADING', false);
                } else {
                    commit('SET_USER_LIST', []);
                    commit('SET_IS_LOADING', true);
                }
            }).catch(() => {
                commit('SET_USER_LIST', []);
                commit('SET_IS_LOADING', true);
            });
        }
    }
}
