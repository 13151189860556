<template>
    <div>
        <v-app-bar app
            dark
            dense
        >
            <v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon>
            <v-app-bar-title>StickFix Latvia</v-app-bar-title>

            <v-spacer></v-spacer>

            <div v-if="!authenticated">
                <v-btn
                    text
                    v-for="item in unauthItems"
                    :key="item.title"
                    :to="item.path"
                    small
                >
                    <v-icon>{{ item.icon }}</v-icon>
                    <div class="hidden-sm-and-down">{{ item.title }}</div>
                </v-btn>
            </div>

            <div v-else>
                <v-menu
                    left
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ user.username }}
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="item in getVisibleActions(authItems.paths)"
                            :key="item.title"
                            :to="item.path"
                        >
                            <v-list-item-icon>
                                <v-icon color="primary">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item
                            v-for="item in authItems.methods"
                            :key="item.title"
                            @click.prevent="userOptions(item.method)"
                        >
                            <v-list-item-icon>
                                <v-icon color="primary">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

        <v-navigation-drawer
            v-if="!isAuthLoading"
            v-model="sidebarMenu"
            app
            floating
            :permanent="sidebarMenu"
            :mini-variant="toggleMini"
        >
            <v-list dense color="primary" dark>
                <v-list-item>
                    <v-list-item-action>
                        <v-icon @click="toggleMiniMethod()">{{ toggleMiniIcon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="center-image">
                        <v-list-item-title>
                            Navigācijas panelis
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list-item v-if="authenticated" class="px-2" :to="{ name: 'Account' }">
                <v-list-item-avatar>
                    <v-icon>mdi-account-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="text-truncate">
                    {{ user.username }}
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list>
                <v-list-item v-for="item in sideNavItems" :key="item.title" link :to="item.href">
                    <v-list-item-icon>
                        <v-icon color="primary">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list v-if="authenticated">
                <v-list-item
                    v-for="item in getVisibleActions(sideNavAuthItems)"
                    :key="item.title" link
                    :to="item.href"
                >
                    <v-list-item-icon>
                        <v-icon color="primary">{{ item.icon }}</v-icon>
                        <v-badge v-if="item.title === 'Ziņas' && unreadMessageCount"
                            color="green"
                            :content="unreadMessageCount"
                        >
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Nav',

        data: () => ({
            appTitle: 'StickFix Latvia',
            sidebarMenu: true,
            toggleMini: true,
            toggleMiniIcon: 'mdi-chevron-right',

            unauthItems: [
                { title: 'Pieslēgties', path: '/login', icon: 'mdi-login-variant' }
            ],

            authItems: {
                paths: [
                    { title: 'Panelis', path: '/acp', icon: 'mdi-shield-account', role: 'enterAdminCp' },
                    { title: 'Profils', path: '/account', icon: 'mdi-settings' },
                ],
                methods: [
                    { title: 'Iziet', method: 'signOut', icon: 'mdi-logout' },
                ]
            },

            errors: {
                message: null,
                visible: false,
            },

            sideNavItems: [
                { title: 'Sākums', href: '/', icon: 'mdi-home-outline' },
                { title: 'Preces', href: '/adverts', icon: 'mdi-view-module' },
            ],

            sideNavAuthItems: [
                { title: 'Panelis', href: '/acp', icon: 'mdi-shield-account', role: 'enterAdminCp' },
                { title: 'Ziņas', href: '/messages', icon: 'mdi-email-outline', role: 'readMessages' },
            ],
        }),

        computed: {
            isSuperAdmin() {
                return this.roles.some(record => record.role === 'superAdmin');
            },
            ...mapGetters({
                authenticated: 'auth/authenticated',
                user: 'auth/user',
                roles: 'auth/roles',
                isAuthLoading: 'auth/isLoading',
                unreadMessageCount: 'messages/unreadMessageCount',
            }),
        },

        methods: {
            ...mapActions({
                signOutAction: 'auth/logout',
            }),
            async signOut () {
                await axios.get('/api/logout').then((response) => {
                    if (response.data.success) {
                        this.signOutAction();
                        this.$router.replace({ name: 'Home' }).catch(() => {});
                    }
                }).catch((error) => {
                    this.errors.message = 'Kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz!';
                    this.errors.visible = true;
                });
            },
            userOptions(name) {
                this[name]();
            },
            toggleMiniMethod() {
                this.toggleMini = !this.toggleMini;
                if (this.toggleMini) {
                    this.toggleMiniIcon = 'mdi-chevron-right';
                } else {
                    this.toggleMiniIcon = 'mdi-chevron-left';
                }
                return this.toggleMini;
            },
            getVisibleActions(list) {
                if (this.roles) {
                    return list.filter(item => this.isSuperAdmin || !item.role || this.userHasRole(item.role));
                } else {
                    return [];
                }
            },
            userHasRole(role) {
                return this.roles.some(record => record.role === role);
            },
        },
    }
</script>

<style scoped>

</style>
